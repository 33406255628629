import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import Grid from "@material-ui/core/Grid";
import Collapse from '@material-ui/core/Collapse';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Box from '@material-ui/core/Box';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import React, { useEffect, useState } from "react";
import { urlConsulta, urlConsultaDet, getString} from "../../utils";
import { useStore } from "../../context";
import "./index.css";
import { httpGet } from "../../servicios";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PagoCab from "../../models/PagoCab";
var _ = require('lodash');
const app = require("../../utils/app_es.json");

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  
});

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  return (
    <React.Fragment>
      <TableRow hover  className={classes.root} >
        <TableCell>
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
        </TableCell>
        <TableCell align="center">
            {row.referencia}
        </TableCell>

        <TableCell align="center">
          {`${row.valorTotal}`}
        </TableCell>

        <TableCell align="center" >
          {row.formaPago}
        </TableCell>

        <TableCell align="center">
          {`${row.fechaProceso+' '+row.horaProceso}`}
        </TableCell>

        <TableCell align="center">
          {row.mensajeProceso}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                  Detalle
              </Typography>
              <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Cod. Est</TableCell>
                      <TableCell>Nombres</TableCell>
                      <TableCell >Cod. Rubro</TableCell>
                      <TableCell >Rubro</TableCell>
                      <TableCell >Valor</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.detalle.map((item, index) => (
                  
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {item.codEst.trimEnd()}
                        </TableCell>
                        <TableCell>{item.nomEst.trimEnd()}</TableCell>
                        <TableCell >{item.codRub}</TableCell>
                        <TableCell >
                          {item.dscRub}
                        </TableCell>
                        <TableCell >
                          {`$${item.valorProcc}`}
                        </TableCell>
                      </TableRow>
                      
                    ))}

                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}
export default function Dashboard() {
  const classes = useStyles();
  const { state } = useStore();
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  //const [total, setTotal] = useState(0);
  const [error, setError] = useState(false);
  //const [open, setOpen] = React.useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#005ecb',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  useEffect(() => {
    setLoading(true);
    httpGet(urlConsulta+sessionStorage.getItem("username"),state.token)
    .then((res) => {
      if(res.cod === 0){
        if(res.data.error){
          setError(true);
          return;
        }
        var data = [];
        let _cab = {};
        res.data.map(async(item)=>{
          //Lamar al detalle de cada cabecera
            let result = await httpGet(urlConsultaDet+item.idSobre.trimEnd(),state.token);
            //.then((result)=>{
              if(result.cod === 0){
                  _cab = new PagoCab (item.idSobre.trimEnd(), item.moneda,  item.valorTotal +' '+ item.moneda, item.formaPago, item.nomCli, item.fechaProceso,
                                        item.horaProceso, item.condProceso, getString(app, item.mensajeProceso.split('-')[0]), item.emailPayer, item.docPayer, item.referencia  !== null ? item.referencia.trimEnd():item.referencia,result.data)
                  data.push(_cab);
              }else{
                  _cab = new PagoCab (item.idSobre.trimEnd(), item.moneda,item.valorTotal +' '+ item.moneda, item.formaPago, item.nomCli, item.fechaProceso,
                                        item.horaProceso, item.condProceso, getString(app, item.mensajeProceso.split('-')[0]), item.emailPayer, item.docPayer,
                                        item.referencia  !== null ? item.referencia.trimEnd():item.referencia,[])
                  data.push(_cab);
              }
            //})
            //data.push(_cab)
          })
          setTimeout(()=>{
            const myOrderedArray = _.sortBy(data, o => o.idSobre.trimEnd()).reverse();
            setLoading(false)
            setRows(myOrderedArray);
          },2500)
          
          /*let sumatoria = 0;
          res.data.map((item) => {
            sumatoria = sumatoria + parseFloat(item.valor);
          });
          // dispatch({ type: "ADD_CART", payload: data });
          setTotal(sumatoria.toFixed(2));*/
      }else{
        setError(true);
        setLoading(false);
        setRows([]);
      }
      
    }).catch((err)=>{
        setError(true);
        setLoading(false);
        console.log("Error:",err)
      })
  }, [state]);

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        Hubo un error al traer los datos —{" "}
        <strong>
          <a href="/pension/pagos">Reintentar.</a>
        </strong>
      </Alert>
    );
  }
  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
      <Table stickyHeader >
        <TableHead >
          <TableRow>
            <StyledTableCell/>
            <StyledTableCell align="center">Referencia</StyledTableCell>
            <StyledTableCell align="center">Total</StyledTableCell>
            <StyledTableCell align="center">Forma Pago</StyledTableCell>
            <StyledTableCell align="center">Fecha de pago</StyledTableCell>
            <StyledTableCell align="center">Estado</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
            <Row key={index} row={row} />
          ))}
        </TableBody>
      </Table>
      </TableContainer>
       <TablePagination
        labelRowsPerPage="Filas por páginas"
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      {/* <h1>{total}</h1> */}
    </Paper>
  );
}
