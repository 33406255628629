import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { CopyRight } from "../components/Copyright";
import Header from "../components/Header";
import Menu from "../components/Menu";
import "./index.css";
import { useStore } from "../context";
import { navigate } from "@reach/router";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
}));

export default function PrivateRoute({ children }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const { state } = useStore();
  React.useEffect(() => {
    if (!state.token) {
      navigate("/");
    }
  }, [state]); 

  return (
        <>
          {children}
        </>
  );
}
