import React, { useState, useRef} from "react";
import { useStore } from "../../context";
import {navigate } from "@reach/router";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { CopyRight } from "../../components/Copyright";
import {urlAuth, urlGetGrados, urlOpciones} from "../../utils";
import Info from "../../components/Info/Info";
import { httpGet } from "../../servicios";
import Link from '@material-ui/core/Link';
import { red } from "@material-ui/core/colors";
import { CircularProgress } from "@material-ui/core";
import Pdf from "../../components/Pdf";



const AuthMain = () => {
  const { dispatch } = useStore();
  const [user, setUser] = useState("");
  const [passw, setPassword] = useState("");
  const [notif, setNotif] = useState(false);
  const [Loading, setLoading] = useState(false);
  let refe = useRef();
  
  function Login(event){
    event.preventDefault()
    setLoading(true);
    const data = {
      "username":"A"+user,
      "password":passw
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data)
    };
    

    fetch(`${urlAuth}auth/login`,options)
      .then((res) => {     
        return res.json()
      })
      .then(async(data) => {
        // console.log("el token es:"+data.token)
        let _opciones = await httpGet(urlOpciones,data.token);
        let _grados   = await httpGet(urlGetGrados,data.token);
        if(data.token){
          dispatch({type:"SET_OPCIONES", payload:_opciones.data});
          dispatch({type:"SET_GRADOS",   payload:_grados.data})
          dispatch({ type: "SET_TOKEN", payload: data });
          // navigate("/pension/list");
          
          navigate("/menuMain");
        }else{
          setNotif(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        setNotif(true);
        setLoading(false);
        console.log("Error de promesa:",err);
      })
  }

  

  return (
    
    <div className="main-app">
      {/* {Loading == true ? <CircularProgress /> : <div></div>} */}
      <Container component="main" maxWidth="xs">
        <div className="content-login">
          <Typography component="h1" variant="h5">
            Sistema de pagos, inscripciones y matriculación de ANAI
          </Typography>
          <form className="form-login" noValidate onSubmit={Login}>
            <Tooltip title='Digite la cédula o ruc de la persona  a quien se emite la factura en Colecturia' arrow>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Usuario"
                name="username"
                autoComplete="username"
                value={user}
                onChange={(e) => setUser(e.target.value)}
                autoFocus />
            </Tooltip>

            
            <Tooltip title='Si la clave no ha sido cambiada es la misma cédula o ruc ingresada en usuario'  arrow>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                value={passw}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"    />
            </Tooltip>
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="button-login"
              disabled={Loading}
            >
              {Loading?<CircularProgress/> : "INGRESAR"}

            </Button>
            
          </form>

          <div style={{flexDirection:"row", direction:"initial"}}>
            <Tooltip title='Es sólo para aspirantes a cadetes'  arrow>
              <Link onClick={()=>{navigate("/registroAnai")}}>
                Registro por primera vez
              </Link>
            </Tooltip>
            <Typography variant="caption"> </Typography>
          </div>
          <div style={{flexDirection:"row", direction:"initial"}}>
            <Tooltip   title='Para recuperar la contraseña debe ingresar el codigo del estudiante@anai.edu.ec, Ejemplo 099999@anai.edu.ec'  arrow>
              <Link onClick={()=>{navigate("/recuperaMenu")}} >
                Olvidó su contraseña.
              </Link>
            </Tooltip>
            <Typography variant="caption"></Typography>
          </div>
          <div style={{   }}>
            <Pdf />
            <Typography variant="caption"></Typography>
          </div>
        </div>
        <CopyRight />
        <Info 
          ref={refe} 
          visible={notif} 
          titulo={`Error en login de Pensiones`} 
          mensaje={`Credenciales Incorrectas`} 
          cerrar={setNotif}
        />
      </Container>
    </div>
  
  );
};

export default AuthMain;
