export default class Usuario{
    constructor(
        tipoDoc,
        identificacion,
        nombres,
        apellidos,
        clave,
        estado,
        fechaCrea,
        fechaModifica,
        authority
    ){
        this.tipoDoc = tipoDoc;
        this.identificacion = identificacion;
        this.nombres = nombres;
        this.apellidos = apellidos;
        this.clave = clave;
        this.estado = estado;
        this.fechaCrea = fechaCrea;
        this.fechaModifica = fechaModifica;
        this.authority = authority;
    }
}