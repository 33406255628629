
//-------------------------------------------------------------------------
// Inicializacion de los valores del state del redux
//-------------------------------------------------------------------------

const cartInitial = sessionStorage.getItem("cart")  ? JSON.parse(sessionStorage.getItem("cart"))  : [];

const cartInitialIns = sessionStorage.getItem("cartIns")  ? JSON.parse(sessionStorage.getItem("cartIns"))  : [];

const cartInitialmAT = sessionStorage.getItem("cartMat")  ? JSON.parse(sessionStorage.getItem("cartMat"))  : [];

const pagadorInitial= sessionStorage.getItem("pagador")  ? JSON.parse(sessionStorage.getItem("pagador"))  :{}

const opcionesInitial = sessionStorage.getItem("opciones")  ? JSON.parse(sessionStorage.getItem("opciones"))  :{}

const gradosInitial = sessionStorage.getItem("grados")  ? JSON.parse(sessionStorage.getItem("grados"))  :[];

//-------------------------------------------------------------------------
// Seteo de valores por defecto
//-------------------------------------------------------------------------
export const defaultState = {
  token: sessionStorage.getItem("token"),
  login: null,
  cart: cartInitial,
  cartIns:cartInitialIns,
  cartMat: cartInitialmAT,
  keys: null,
  idPayment:null,
  username: "",
  processUrl: "",
  pagador: pagadorInitial,
  opciones : opcionesInitial,
  grados: gradosInitial
};

//-------------------------------------------------------------------------
// REDUCER
//-------------------------------------------------------------------------
function reducer(state, action) {
  switch (action.type) {
    case "SET_TOKEN":
      sessionStorage.setItem("token", action.payload.token);
      sessionStorage.setItem("username", action.payload.username);
      return { ...state, token: action.payload.token, username: action.payload.username };
    case "REMOVE_TOKEN":
      sessionStorage.removeItem("token");
      return { ...state, token: null };
    case "REMOVE_USER":
      sessionStorage.removeItem("username");
      return { ...state, username: null };
    case "ADD_CART":
      const cart = state.cart.concat(action.payload);
      sessionStorage.setItem("cart", JSON.stringify(cart));
      return {
        ...state,
        cart: cart,
      };
    case "ADD_CART_INS":
      const cartIns = state.cartIns.concat(action.payload);
      sessionStorage.setItem("cartIns",JSON.stringify(cartIns));
      return {
        ...state,
        cartIns: cartIns,
      };
    case "ADD_CART_MAT":
      const cartMat = state.cartMat.concat(action.payload);
      sessionStorage.setItem("cartMat",JSON.stringify(cartMat));
      return {
        ...state,
        cartMat: cartMat,
      };
    case "SET_GRADOS":
      const grados = action.payload;
      let _grados = [];
     
      grados.forEach((item)=>{
        _grados.push({value:item.codigo, label:item.descripcion})
      })
      sessionStorage.setItem("grados",JSON.stringify(_grados));
      return{
        ...state,
        grados:_grados
      }
    case "REMOVE_GRADOS":
      sessionStorage.removeItem("grados");
      return { ...state, grados: null };
    case "ADD_PAGADOR":
    const pagador = action.payload;
    sessionStorage.setItem("pagador",JSON.stringify(pagador));
      return{
        ...state,
        pagador: pagador
      }
    case "REMOVE_PAGADOR":
      sessionStorage.removeItem("pagador");
      return { ...state, pagador: null };
    case "ADD_KEYS":
    const keys = action.payload;
    sessionStorage.setItem("keys", JSON.stringify(keys));
    return {
      ...state,
      keys: keys,
    };
    case "REMOVE_KEYS":
      sessionStorage.removeItem("keys");
      return { ...state, keys: null };
    case "SET_OPCIONES":
      const _opciones = action.payload;
      sessionStorage.setItem("opciones", JSON.stringify(_opciones));
    return {
      ...state,
      opciones: _opciones,
    };
    case "REMOVE_OPCIONES":
      sessionStorage.removeItem("opciones");
      return { ...state, opciones: null };
    case "ID_PAYMENT":
      const idPayment = action.payload;
      sessionStorage.setItem("idPayment", JSON.stringify(idPayment));
      return {
        ...state,
        idPayment: idPayment,
      };
    case "REMOVE_ID_PAYMENT":
      sessionStorage.removeItem("idPayment");
      return { ...state, idPayment: null };
    case "REMOVE_CART_INS":
      const newCartIns = state.cartIns.filter(
        (item) =>
          item.numDoc !==
          action.payload.numDoc
      );
      sessionStorage.setItem("cartIns", JSON.stringify(newCartIns));
      return {
        ...state,
        cartIns: newCartIns,
      };
    case "REMOVE_CART_MAT":
      const newCartMat = state.cartMat.filter(
        (item) =>
          item.sta_obl !==
          action.payload.sta_obl
      );
      sessionStorage.setItem("cartMat", JSON.stringify(newCartMat));
      return {
        ...state,
        cartMat: newCartMat,
      };
    case "REMOVE_CART":
      const newCart = state.cart.filter(
        (item) =>
          item.periodo + item.codNiv + item.codEst + item.codRub !==
          action.payload.periodo +
            action.payload.codNiv +
            action.payload.codEst +
            action.payload.codRub
      );
      sessionStorage.setItem("cart", JSON.stringify(newCart));
      return {
        ...state,
        cart: newCart,
      };
    case "RESET_CART":
      sessionStorage.removeItem("cart");
      return {
        ...state,
        cart: [],
      };
    case "RESET_CART_INS":
      sessionStorage.removeItem("cartIns");
      return {
        ...state,
        cartIns: [],
      };
    case "RESET_CART_MAT":
      sessionStorage.removeItem("cartMat");
      return {
        ...state,
        cartMat: [],
      };
    case "SET_LOGIN":
      const login = action.payload;
      sessionStorage.setItem("login",JSON.stringify(login));
      return{
        ...state,
        login: action.payload
      }
    case "REMOVE_LOGIN":
      sessionStorage.removeItem("login");
      return { ...state, login: null };
    case "SET_PROCESS_URL":
      sessionStorage.setItem("processUrl",action.payload)
      return{
        ...state,
        processUrl: action.payload
      }
    case "REMOVE_PROCESS_URL":
      sessionStorage.removeItem("processUrl");
      return { ...state, processUrl: null };
    default:
      return state;
  }
}

export default reducer;
