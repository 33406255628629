import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import React, { useEffect, useState, useRef } from "react";
import { useStore } from "../../../context";
import MatStudentCard from "../../../components/MatStudentCard";
import { urlMat, urlMatNew } from "../../../utils";
import { httpGet } from "../../../servicios";
import Info from "../../../components/Info/Info";

export default function MatriculaList() {
  const { state } = useStore();
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [error, setError] = useState(false);
  const [notif, setNotif] = useState(false);
  const [mensajeError, setMensajeError] = useState("");
  let refe = useRef();

  useEffect(() => {
    setLoading(true);
    const tipoUserMatricula = JSON.parse(sessionStorage.getItem('login'))
    let urlTemp = urlMat+sessionStorage.getItem("username");
    if(tipoUserMatricula === "N"){
      urlTemp = urlMatNew+sessionStorage.getItem("username");
    }
    console.log("el tipousermatricula es "+tipoUserMatricula);
    console.log("el turltemp es "+urlTemp); 

    //--------------------------------------------------------------------------------------
    // CARGAR LAS FILAS EN LA TABLA
    //--------------------------------------------------------------------------------------
    httpGet(urlTemp, state.token)
    .then((res) => {
      if(res.cod === 0){
        console.log("Respuesta:",res)
        if(res.data.error){
          setError(true);
          setNotif(true);
          setMensajeError("Hubo un error en la consulta");
          return;
        }
          if(res.data.length===0){
            setNotif(true);
            setMensajeError("Sin matriculas que mostrar");
          }
          setRows(res.data);
      }else{
        setError(true)
        setRows([])
        setNotif(true);
        setMensajeError("Error en la consulta");
      }
    }).catch((err)=>{
      setNotif(true);
      setMensajeError("Error fatal con el servidor!");
      setError(true);
      })
    .finally(() => setLoading(false));
  }, [state]);

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        Hubo un error al cargar los datos —{" "}
        <strong>
          <a href="/matricula/list">Intentar de nuevo.</a>
        </strong>
      </Alert>
    );
  }
  return (
    <Paper className="container-table">
      <Info 
          ref={refe} 
          visible={notif} 
          titulo={`Matriculas Estudiante ANAI`} 
          mensaje={mensajeError} 
          cerrar={setNotif}
        />
      <Grid>
        {rows.map((row) => {
          return (
            <MatStudentCard
              student={row}
              key={row.cod_est}
            />
          );
        })}
      </Grid>
    </Paper>
  );
}
