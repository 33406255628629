import { useStore } from "../../../context";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useState, useRef, useCallback } from "react";
import { server } from "../../../utils";
import "./index.css";
import Info from "../../../components/Info/Info";
import { httpGet, httpPost } from "../../../servicios";
import WebLink from '../../../models/WebLink';
import { navigate } from "@reach/router";

const LinkMat = () => {
  const { state, dispatch } = useStore();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [notif, setNotif] = useState(false);
  const [mens, setMens] = useState("");
  let refe = useRef();

  function Link(event){
    event.preventDefault()
    let data = [];
    //console.log("State actual:",state.cartMat)
    state.cartMat.forEach((val,ind)=>{
      data.push(new WebLink(
        0,
        " ",
        ind+1,
        val.codEst,
        val.est,
        val.periodo,
        val.cod_niv,
        val.cod_rub,
        val.des_rub,
        val.course,
        val.val_aut,
        "0",
        email,
        new Date(),
        sessionStorage.getItem("username"),
        state.token
      ));
    });
    httpGet(`${server}config`,state.token)
      .then((res)=>{
        if(res.data.link_secreto===pass){
          httpPost(`${server}link/pago/matricula`,data,state.token)
          .then((res)=>{
            setNotif(true);
            setMens("Generado Link exitosamente!");
            dispatch({ type: "RESET_CART_MAT" });
            dispatch({ type: "REMOVE_LOGIN"});
            dispatch({ type: "REMOVE_PROCESS_URL" });
            dispatch({ type: "REMOVE_USER" });
            dispatch({ type: "REMOVE_ID_PAYMENT" });
            dispatch({ type: "REMOVE_OPCIONES" });
            dispatch({ type: "REMOVE_KEYS" });
            setTimeout(()=>{
              dispatch({ type: "REMOVE_TOKEN" });
            },2200)
            console.log("Respuesta:",res)
          })
          .catch(err=>{
            setNotif(true);
            setMens("Hubo un error en generación de Link!");
            console.log("Error en generacion de Link:",err)
          });
        }else{
          setNotif(true);
          setMens("Contraseña Incorrecta!");
        }
      })
      .catch(err=>console.log("Error en recuperar credenciales de seguridad:",err));
  }
  return (
    <div>
      <Container component="main" maxWidth="xs">
        <div className="content-login">
          <Typography component="h1" variant="h5">
            Pagar matricula de Estudiante Anai
          </Typography>
          <form className="form-login" noValidate onSubmit={Link}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              type="password"
              id="password"
              label="Contraseña"
              name="password"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="button-login"
            >
              Generar Link de Pago
            </Button>
          </form>
        </div>
        <Info 
          ref={refe} 
          visible={notif} 
          titulo={`Generación de Link`} 
          mensaje={mens} 
          cerrar={setNotif}
        />
      </Container>
    </div>
  );
};

export default LinkMat;
