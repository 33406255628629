import React, { useEffect, useState, createRef, useRef } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox } from '@material-ui/core';
import { useStore } from "../../../context";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";
import CardMedia from '@material-ui/core/CardMedia';
import PaymentIcon from "@material-ui/icons/Payment";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import { httpPost, getIp, httpGet, httpPostProxy } from "../../../servicios";
import { placeToPay, user, password, urlReferencia, urlRespuestaMat, urlPagoMat, PROD, saveLog, saveCab, saveDet } from "../../../utils";
import RedirectionAuth from "../../../utils/autentication";
import Info from "../../../components/Info/Info";
import sendLog from "../../../utils/logs";
import PagoWebCab from '../../../models/PagoWebCab';
import PagoWebDet from "../../../models/PagoWebDet";
import LogPago from "../../../models/LogPago";
import { Link, navigate } from "@reach/router";
import { blue, red } from "@material-ui/core/colors";

var moment = require('moment');
var _ = require('lodash');

const creditI = require("../../../images/logos/logos_EC.png")
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

export default function Cart(props) {
  const { state, dispatch } = useStore();
  const [checked, setChecked] = useState(false);
  const [checkedLink, setCheckedLink] = useState(false);
  const [open, setOpen] = useState(false);
  const [itemsToPay, setItemsToPay] = useState();
  const buttonPayment = createRef();
  const [notif, setNotif] = useState(false);
  let refe = useRef();

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleChangeLink = (event) => {
    setCheckedLink(event.target.checked);
  };

  const classes = useStyles();
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let sumatoria = 0;
    state.cartMat.map((item) => {
      sumatoria = sumatoria + parseFloat(item.val_rub);
    });
    setTotal(sumatoria.toFixed(2));
    setItemsToPay({
      order_amount: sumatoria,
      order_description: "Pago matriculas",
    });
    setCheckedLink(false);
    setChecked(false);
  }, [state.cartMat]);

  //-------------------------------------------------------------------------
  // Seteo de por medio de Hooks
  //-------------------------------------------------------------------------
  const validateRemove = (row) => {
    var _newArray = [];
    state.cartMat.map((item) => {
      if (item.codEst === row.codEst) {
        _newArray.push(item)
      }
    })
    _newArray = _newArray.sort((a, b) => {
      if (b.codRub < a.codRub) return 1;
      if (b.codRub > a.codRub) return -1;
      return 0;
    })

    //var length = _newArray.length;
    var index = _.findIndex(_newArray, { 'cen_cos': row.cen_cos });
    if (row.sta_obl !== "S") {
      dispatch({ type: "REMOVE_CART_MAT", payload: row });
    } else {
      alert("No puede eliminar esta matricula poque es obligatorio!");
    }
    return;


  }
  const renderRows = () => {
    return state.cartMat.map((row, index) => (
      <TableRow key={index}>
        <TableCell>{row.periodo}</TableCell>
        <TableCell align="right">{row.codEst}</TableCell>
        <TableCell align="right">{row.est}</TableCell>
        <TableCell align="right">{row.cod_rub}</TableCell>
        <TableCell align="right">{row.cen_cos}</TableCell>
        <TableCell align="right">{row.course}</TableCell>
        <TableCell align="right">{row.des_rub}</TableCell>
        <TableCell align="right">$ {ccyFormat(row.val_rub)}</TableCell>
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            endIcon={<RemoveShoppingCartIcon />}
            size="small"
            onClick={() => { validateRemove(row) }}
          >
            Remover
          </Button>
        </TableCell>
      </TableRow>
    ));
  };

  const handleClickOpen = () => { setOpen(true) };
  const handleClose = () => { setOpen(false) };

  const createKeys = (keys) => { dispatch({ type: "ADD_KEYS", payload: keys }) };

  const createIdPayment = (id) => { dispatch({ type: "ID_PAYMENT", payload: id }) };

  //Metodo de pagar carrito
  const payment = async (total) => {
    if (checkedLink) {
      navigate("linkMat");
    }
    try {

      if (checked === false) {
        setNotif(true);
        return;
      }
      setLoading(true);
      var authGenerator = "";
      var auth = "";
      var referencia = null;
      let consulta = await httpGet(saveCab + "/" + sessionStorage.getItem("username"), state.token)
        .then((res) => {
          return res;
        })
        .catch(err => console.log("Error en consulta de cabecera:", err));

      if (consulta.cod !== 0) {
        alert(consulta.mensaje);
        setLoading(false);
        return;
      }

      //return
      authGenerator = new RedirectionAuth(user, password);
      auth = authGenerator.asObject();
      createKeys(auth);
      //var ip = await getIp();

      var hoy = moment();
      hoy.add(20, 'minutes');
      const { cartMat } = state;
      var tipIden = "CI";
      //Datos necesarios para generar data a enviar a place to play
      let _referencia = await httpGet(urlReferencia + sessionStorage.getItem("username"), state.token);
      if (_referencia.cod !== 0) {
        alert(_referencia.mensaje);
        return;
      }
      referencia = sessionStorage.getItem("username") + _referencia.data;
      let telefono = "";
      let data = {
        "auth": {
          "login": auth.login,
          "seed": auth.seed,
          "nonce": auth.nonce,
          "tranKey": auth.tranKey
        },
        "locale": "es_EC",
        "buyer": {
          "document": sessionStorage.getItem("username"), //"0940193931",
          "documentType": tipIden,
          "name": " ", // nombres[2] +" "+ nombres[3]
          "surname": " ",//nombres[0]+" "+nombres[1]
          "email": " ", // "painfranco@gmail.com",
          "mobile": telefono,
          "address": {
            "street": " ",//ip.data.state,
            //"city": ip.data.city,
            //"country": ip.data.country_code
            "city": "Guayaquil",
            "country": "EC"
          }
        },
        "payment": {
          "reference": referencia, //sessionStorage.getItem("username"),
          "description": "Pago Matricula",
          "amount": {
            "currency": "USD",
            "total": `${total}`
          },
          "allowPartial": false
        },
        "expiration": hoy,
        "returnUrl": urlRespuestaMat + sessionStorage.getItem("username") + auth.nonce,//"http://superexito.com.ec/",
        "userAgent": navigator.userAgent, // "Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/84.0.4147.125 Safari/537.36",
        //"ipAddress": ip.data.IPv4
        "ipAddress": "40.76.73.208"
      }

      let response = await httpPostProxy(urlPagoMat, data, placeToPay)
        .then(res => {
          return res
        })
        .catch(err => console.log("Error en segundo proxy:", err));


      if (response.cod !== 0) {
        alert(response.mensaje);
        setLoading(false);
        return;
      }

      if (JSON.parse(response.data).status.status === 'OK') {
        createIdPayment(JSON.parse(response.data).requestId);
        //---------------------------------------------------------------------------------------------------------------
        //IMPORTANTE: response es el resultado de consultar a place to play por medio del proxy
        let _idPayment = JSON.parse(response.data).requestId;
        let data = JSON.parse(response.data);
        let date = moment(data.status.date);
        var _logArray = [];

        //--------------------------------------------------------------------------------------
        // ENVIO DE LOG
        //--------------------------------------------------------------------------------------
        state.cartMat.forEach((item, index) => {
          var logPago = new LogPago(
            _idPayment,
            (index + 1).toString(),
            "USD",
            item.val_aut,
            data.status.reason,
            "PENDING-" + data.status.message,
            date.format("YYYY-MM-DD"),
            date.format("HH:mm"),
            sessionStorage.getItem("username"),
            " ",
            item.cod_rub,
            item.codEst,
            item.cen_cos,
            item.periodo,
            sessionStorage.getItem("username"),
            referencia,
          )
          _logArray.push(logPago)
        })

        //-------------------------------------------------------------------------
        // La banderas para redireccionar - Forma de asegurar grabacion
        //-------------------------------------------------------------------------
        let isLog = false;
        let isCab = false;
        let isDet = false;

        //-------------------------------------------------------------------------
        // Grabar Log a la Base de Datos
        //-------------------------------------------------------------------------
        isLog = await httpPost(saveLog, _logArray, state.token)
          .then(async (result) => {
            isLog = true;
            return isLog;
          })
          .catch(err => console.log("Error al guardar log:", err))

        //--------------------------------------------------------------------------------------
        // ENVIO DE CABECERA DE PAGO WEB
        //--------------------------------------------------------------------------------------
        const pagoCab = new PagoWebCab(
          _idPayment,
          "USD",
          total,
          "TAR",
          "",
          "",
          "",
          tipIden,
          sessionStorage.getItem("username"),
          " ",
          date.format("YYYY-MM-DD"),
          date.format("HH:mm"),
          data.status.reason,
          "PENDING-" + data.status.message,
          sessionStorage.getItem("username"),
          "WEB",
          "",
          "",
          "",
          "",//correo
          sessionStorage.getItem("username"),//docPayer
          referencia,
          data.processUrl ? data.processUrl : "Sin processUrl retornado de place to Pay",
          JSON.parse(sessionStorage.getItem('login')) === "N" ? "MN" : "ME"
        )

        //-------------------------------------------------------------------------
        // Guardar cabecera y creacion y grabacion de detalle de pago
        //-------------------------------------------------------------------------
        isCab = await httpPost(saveCab, pagoCab, state.token)
          .then(async (result) => {
            if (result.cod === 0) {
              let _data = result.data;
              //console.log("Detalle entro:",result);
              //-------------------------------------------------------------------------
              // ENVIO DE DETALLE DE PAGO WEB
              //-------------------------------------------------------------------------
              let pagoDet = [];
              state.cartMat.forEach((item, index) => {
                var _pagoDet = new PagoWebDet(
                  _data.idSobre,
                  (index + 1).toString(),
                  item.val_rub,
                  item.est.trim(),
                  item.course,
                  item.des_rub,
                  _data.fechaProceso,
                  sessionStorage.getItem("username"),
                  "",
                  "",
                  "",
                  "",
                  item.periodo,
                  item.cen_cos,
                  item.codEst,
                  item.cod_rub,
                  _data.fechaProceso,
                  "",
                  "",
                  "",
                  "",
                  "",
                  0,
                  "",
                  item.idInscr ? item.idInscr : 0
                )
                pagoDet.push(_pagoDet)
              })
              sendLog(PROD, "Matricula Cart:", JSON.stringify(state.cartMat), JSON.parse(response.data).requestId, state.token)
              //-------------------------------------------------------------------------
              // Guardar el detalle de pago
              //-------------------------------------------------------------------------
              sendLog(PROD, "Detalle Matricula", JSON.stringify(pagoDet), JSON.parse(response.data).requestId, state.token)
              isDet = await httpPost(saveDet, pagoDet, state.token)
                .then(async (result) => {
                  if (result.data.length === pagoDet.length) {
                    isDet = true;
                    return isDet;
                  }
                })
                .catch(err => console.log("Error al guardar detalle:", err))
              return true;
            }
          });

        //-------------------------------------------------------------------------
        // Redireccionamiento a place to pay si se grabo los objetos a BD
        //-------------------------------------------------------------------------
        if (isLog && isCab && isDet) {
          sendLog(PROD, "Carrito de Matricula en estado OK", response.data, JSON.parse(response.data).requestId, state.token);
          window.location.assign(JSON.parse(response.data).processUrl);
        } else {
          sendLog(PROD, "Error en Carrito de Matricula", "Estado de banderas.Log:" + isLog + "Cab:" + isCab + "Det:" + isDet, JSON.parse(response.data).requestId, state.token);
          alert("Hubo un error en la transaccion")
        }
        setLoading(false);
      } else {

        alert(JSON.parse(response.data).status.message);
        setLoading(false);
        return;
      }
    } catch (error) {
      alert("No se ha podido proceder con el pago.");
      console.log(error);
      setLoading(false);
    }

  }

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Periodo</TableCell>
              <TableCell align="right">Cod. Est</TableCell>
              <TableCell align="right">Nombres</TableCell>
              <TableCell align="right">Cod. Rubro</TableCell>
              <TableCell align="right">Nivel</TableCell>
              <TableCell align="right">Curso</TableCell>
              <TableCell align="right">Rubro</TableCell>
              <TableCell align="right">Valor</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {renderRows()}
            <TableRow>
              <TableCell colSpan={5}></TableCell>
              <TableCell colSpan={2} style={{ fontWeight: "bold" }}>Total</TableCell>
              <TableCell align="right" style={{ fontWeight: "bold" }}>$ {total}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={8} align="right">
                {/* <button type="submit" className="btn btn-primary" id="form-submit">Pagar $ {total}</button> */}
                <Button
                  ref={buttonPayment}
                  onClick={() => { payment(total) }}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  endIcon={<PaymentIcon />}
                  disabled={state.cartMat.length === 0}
                  size="small"
                >
                  Pagar $ {total}
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>

            </TableRow>
          </TableBody>
        </Table>
        <div style={{ flexDirection: "column", justifyContent: "normal", marginTop: 20 }}>
          <DialogContent style={{ backgroundColor: "#eceff1" }}>
            <Typography gutterBottom variant="h5" style={{ color: "#f44336" }}>
              Los pagos en esta plataforma se procesan en un plazo de 24 horas laborables
            </Typography>
          </DialogContent>
        </div>
        <div style={{ flexDirection: "column", justifyContent: "normal", marginTop: 20 }}>
          <div style={{ width: "100%", textAlign: "justify" }}>
            {/* Dialogo de avisos modal */}
            <Info
              ref={refe}
              visible={notif}
              titulo={`Términos y Condiciones`}
              mensaje={`Debe de aceptar los términos y condiciones para continuar con su pago.`}
              cerrar={setNotif}
            />
            {/* Inicio Terminos y condiciones */}

            <DialogContent style={{ height: "250px", backgroundColor: "#eceff1" }}>
              <Typography variant="h5" gutterBottom>
                Términos y Condiciones
              </Typography>
              <Typography gutterBottom>
                El acceso y uso de este sitio web se rige bajo los términos descritos a continuación.
                El Horario de atención de los pagos es de Lunes a Domingo de 06:00 a 23:00. y serán
                procesados(Factura electrónica) el primer día laborable.
                Los pagos serán con tarjetas de débito, corrientes y diferidos con las siguientes tarjetas:
                Produbanco, Internacional, Bolivariano, Guayaquil ,Diners Club Internacional, Discover,
                Visa Banco Pichincha y Mastercard Banco Pichincha
              </Typography>
              <Typography variant="h6" gutterBottom>
                NOTIFICACIÓN.
              </Typography>
              <Typography gutterBottom>
                LEA LOS PRESENTES TÉRMINOS Y CONDICIONES CUIDADOSAMENTE. AL
                ACCEDER A ESTE SITIO Y A CUALQUIER PÁGINA DEL MISMO SE COMPROMETE A
                CUMPLIR CON LOS TÉRMINOS Y CONDICIONES QUE APARECEN A
                CONTINUACIÓN. EN CASO DE NO ESTAR DE ACUERDO CON LOS SIGUIENTES
                TÉRMINOS Y CONDICIONES NO ACCEDA A ESTE SITIO, O A CUALQUIER PÁGINA
                DEL MISMO.
                Todos los pagos que se ejecuten mediante este sitio web se regirán por los términos y
                condiciones contenidos en este documento, así como por la legislación aplicable en la
                República del Ecuador. Los servicios sólo estarán disponibles para personas que tengan
                capacidad legal para contratar, por lo tanto, no podrán ser utilizados, entre otros, por
                menores de edad, personas inhabilitadas legalmente para contratar y personas que hayan
                incumplido estos términos y condiciones.
                Se entenderá que los usuarios prestan su consentimiento, expreso, libre y voluntario, a los
                términos y condiciones aquí expresados, mediante la aceptación de los mismos a través
                de los medios establecidos en este sitio web.
              </Typography>
              <Typography variant="h6" gutterBottom>
                Usuario o Cliente
              </Typography>
              <Typography gutterBottom>
                La Academia Naval Almirante Illingworth, proveerá al Padre de Familia su usuario y
                contraseña para poder ingresar al portal de pagos.
              </Typography>

              <Typography variant="h6" gutterBottom>
                Contraseña
              </Typography>
              <Typography gutterBottom>
                El cliente registrado asume totalmente la responsabilidad por el mantenimiento de la
                confidencialidad de su contraseña registrada en este sitio web. Dicha contraseña es de
                uso personal y su entrega a terceros no involucra responsabilidad de la Academia Naval
                Almirante Illingworth en caso de mala utilización.
              </Typography>

              <Typography variant="h6" gutterBottom>
                CONSIDERACIONES:
              </Typography>

              <Typography gutterBottom>
                a. El cliente puede solicitar un REVERSO de la transacción sólo en el mismo día que
                genero el pago, enviado un correo a muquillas@anai.edu.ec, indicando nombre del
                cadete, la fecha, #de transacción.
              </Typography>
              <Typography gutterBottom>
                b. El cliente puede realizar una CANCELACION de su transacción en la misma página
                web y antes de haber hecho click en el Botón de Pago
              </Typography>
              <Typography variant="h6" gutterBottom>
                Idioma:
              </Typography>
              <Typography gutterBottom>
                El idioma del presente acuerdo y de sus documentos adicionales es el español, que en
                cualquier caso prevalecerá sobre traducciones o versiones en otro idioma.
              </Typography>

              <Typography variant="h6" gutterBottom>
                Ley aplicable:
              </Typography>
              <Typography gutterBottom>
                El presente Contrato se regirá por las leyes de la República del Ecuador. En caso de surgir
                algún tipo de controversia, las partes renuncian domicilio y se sujetan a los jueces
                competentes de la ciudad de Guayaquil, así como al trámite ejecutivo o verbal sumario.
                El uso que el cliente/usuario hiciere en el portal del botón de pagos, constituye aceptación
                de las mismas.
              </Typography>

              <Typography variant="h6" gutterBottom>
                Límite de responsabilidad:
              </Typography>

              <Typography gutterBottom>
                En ningún caso la Academia Naval Almirante Illingworth será responsable de algún daño,
                incluyendo, sin límite, daños, pérdidas o gastos directos, indirectos, inherentes o
                consecuentes, que surjan en relación con este sitio o su uso o imposibilidad de uso por
                alguna de las partes, o en relación con cualquier falla en el rendimiento, error, omisión,
                interrupción, defecto, demora en la operación o transmisión, virus de computadora o falla
                de sistema o línea. Las conexiones con otros medios de la internet son a su propio riesgo;
                la Academia Naval Almirante Illingworth. no investiga, verifica, controla ni respalda el
                contenido, la exactitud y otras conexiones suministrados por estos medios.
              </Typography>
            </DialogContent>

            {/* Fin Terminos y condiciones */}
            <FormGroup row style={{ justifyContent: "space-between" }}>
              <FormControlLabel
                control={<Checkbox checked={checked} onChange={handleChange} name="checkedA" color="primary" />}
                label="Aceptar Términos y condiciones"
                style={{ fontWeight: "bold" }}
              />
              <FormControlLabel
                control={<Button color="primary" onClick={handleClickOpen} style={{ fontWeight: "bold" }}>{"Preguntas Frecuentes"}</Button>}
                label=""
                style={{ fontWeight: "bold", color: "blue" }} />
            </FormGroup>
          </div>
          <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
              <DialogTitle id="customized-dialog-title" style={{ backgroundColor: "#008ba3" }} onClose={handleClose}>
                PREGUNTAS FRECUENTES SOBRE PAGOS ELECTRÓNICOS
              </DialogTitle>
              <DialogContent dividers style={{ textAlign: "justify" }}>
                <Typography gutterBottom>
                  <h4>1.- ¿Qué es Placetopay? </h4>
                  Placetopay es la plataforma de pagos electrónicos que usa <a href={"!#"} style={{ fontWeight: "bold" }}>ANAI</a> para procesar en línea las transacciones generadas en la tienda virtual con las formas de pago habilitadas para tal fin.
                </Typography>
                <Typography gutterBottom>
                  <h4>2.-¿Cómo puedo pagar? </h4>
                  En la tienda virtual de <a href={"!#"} style={{ fontWeight: "bold" }}>ANAI</a> usted podrá realizar su pago con los medios habilitados para tal fin. Usted, de acuerdo a
                  las opciones de pago escogidas por el comercio, podrá pagar a través de : <a href={"!#"} style={{ fontWeight: "bold" }}>Diners, Discover, Visa y MasterCard</a>;
                  de todos los bancos con pago corriente y en los diferido, únicamente las tarjetas emitidas por Banco Pichincha, Diners, Loja, BGR y Manabí.
                </Typography>
                <Typography gutterBottom>
                  <h4>3.-¿Es seguro ingresar mis datos bancarios en este sitio web? </h4>
                  Para proteger tus datos <a href={"!#"} style={{ fontWeight: "bold" }}>ANAI</a> delega en Placetopay la captura de la información sensible. Nuestra plataforma de pagos cumple con los más altos estándares exigidos por la norma internacional PCI DSS de seguridad en transacciones con tarjeta de crédito. Además tiene certificado de seguridad SSL expedido por GeoTrust una compañía Verisign, el cual garantiza comunicaciones seguras mediante la encriptación de todos los datos hacia y desde el sitio; de esta manera, te podrás sentir seguro a la hora de ingresar la información de su tarjeta.

                  Durante el proceso de pago, en el navegador se muestra el nombre de la organización autenticada, la autoridad que lo certifica y la barra de dirección cambia a color verde. Estas características son visibles de inmediato y dan garantía y confianza para completar la transacción en Placetopay.

                  <a href={"!#"} style={{ fontWeight: "bold" }}> Placetopay </a> también cuenta con el monitoreo constante de McAfee Secure y la firma de mensajes electrónicos con Certicámara.

                  <a href={"!#"} style={{ fontWeight: "bold" }}>Placetopay</a> es una marca de la empresa colombiana EGM Ingeniería Sin Fronteras S.A.S.
                </Typography>
                <Typography gutterBottom>
                  <h4>4.-¿Puedo realizar el pago cualquier día y a cualquier hora? </h4>
                  Sí, en <a href={"!#"} style={{ fontWeight: "bold" }}>ANAI</a> podrás realizar tus compras en línea los 7 días de la semana, las 24 horas del día a sólo un clic de distancia.
                </Typography>

                <Typography gutterBottom>
                  <h4>5.-¿Puedo cambiar la forma de pago? </h4>
                  Si aún no has finalizado tu pago, podrás volver al paso inicial y elegir la forma de pago que prefieras. Una vez finalizada la compra no es posible cambiar la forma de pago.

                </Typography>

                <Typography gutterBottom>
                  <h4>6.-¿Pagar electrónicamente tiene algún valor para mí como comprador? </h4>
                  No, los pagos electrónicos realizados a través de <a href={"!#"} style={{ fontWeight: "bold" }}>Placetopay</a> no generan costos adicionales para el comprador.
                </Typography>

                <Typography gutterBottom>
                  <h4>7.-¿Qué debo hacer si mi transacción no concluyó? </h4>
                  En primera instancia deberás revisar si llegó un mail de confirmación del pago en tu cuenta de correo electrónico (la inscrita en el momento de realizar el pago), en caso de no haberlo recibido, deberás contactar a <a href={"!#"} style={{ fontWeight: "bold" }}>soportepagos@anai.edu.ec</a> para confirmar el estado de la transacción.
                  En caso que tu transacción haya declinado, debes verificar si la información de la cuenta es válida, está habilitada para compras no presenciales y si tienes cupo o saldo disponible. Si después de esto continua con la declinación debes comunicarte con <a href={"!#"} style={{ fontWeight: "bold" }}>ANAI</a>. En última instancia, puedes remitir tu solicitud a <a href={"!#"} style={{ fontWeight: "bold" }}>servicioposventa@placetopay.ec</a>.
                </Typography>

                <Typography gutterBottom>
                  <h4>8.-¿Qué debo hacer si no recibí el comprobante de pago? </h4>
                  Por cada transacción aprobada a través de Placetopay, recibirás un comprobante del pago con la referencia de compra en la dirección de correo electrónico que indicaste al momento de pagar.  Si no lo recibes, podrás contactar a la línea <a href={"!#"} style={{ fontWeight: "bold" }}>+59343703300 </a>
                  o al correo electrónico <a href={"!#"} style={{ fontWeight: "bold" }}>soportepagos@anai.edu.ec</a>, para solicitar el reenvío del comprobante a la misma dirección de correo electrónico registrada al momento de pagar. En última instancia, puedes remitir tu solicitud a <a href={"!#"} style={{ fontWeight: "bold" }}>servicioposventa@placetopay.ec</a>.

                </Typography>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary" variant="contained" >
                  Cerrar
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
        <div style={{ alignItems: "flex-end", marginTop: "5%" }}>
          <a href="http://www.placetopay.com">
            <CardMedia
              style={{ heigth: "20%", width: "25%" }}
              src="https://static.placetopay.com/placetopay-logo.svg"
              component="img"
              title="Placetopay"
            />
          </a>

          <CardMedia
            style={{ width: "80%" }}
            image={creditI}
            component="img"
            title="Tarjetas de crédito"
          />

          <FormControlLabel
            control={<Checkbox checked={checkedLink} onChange={handleChangeLink} name="checkedA" color="primary" />}
            label="Link"
            style={{ fontWeight: "bold" }}
          />
        </div>

      </TableContainer>

    </div>
  );
}
