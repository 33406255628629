import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import React, { useEffect, useState, useRef } from "react";
import { useStore } from "../../context";
import StudentCard from "../../components/StudentCard";
import {urlPensionEstudiantes} from "../../utils"
import { httpGet } from "../../servicios";
import Info from "../../components/Info/Info";

export default function Pensiones() {
  const { state } = useStore();
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [error, setError] = useState(false);
  const [notif, setNotif] = useState(false);
  const [mensajeError, setMensajeError] = useState("");
  const refe = useRef();

  //Carga los estudiantes del representante

  useEffect(() => {
    setLoading(true);
    httpGet(urlPensionEstudiantes+sessionStorage.getItem("username"), state.token)
    .then((res) => {
      if(res.cod === 0){
        if(res.data.error){
          setNotif(true);
          setMensajeError("Error al recuperar pensiones.");
          setError(true);
          return;
        }
        if(res.data.length===0){
          setNotif(true);
          setMensajeError("Sin matriculas que mostrar");
        }
          setRows(res.data);
          
      }else{
        setError(true)
        setRows([])
      }
      
    }).catch(()=>{setError(true);
      })
    .finally(() => setLoading(false));
  }, [state]);

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        Hubo un error al cargar los datos —{" "}
        <strong>
          <a href="/pensiones/list">Intentar de nuevo.</a>
        </strong>
      </Alert>
    );
  }
  return (
    <Paper className="container-table">
      <Info 
          ref={refe} 
          visible={notif} 
          titulo={`Matriculas Estudiante ANAI`} 
          mensaje={mensajeError} 
          cerrar={setNotif}
        />
      <Grid>
        {rows.map((row) => {
          return (
            <StudentCard
              student={row}
              key={row.periodo + row.codNiv + row.codEst}
            />
          );
        })}
      </Grid>
    </Paper>
  );
}
