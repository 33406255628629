import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { useStore } from "../../context";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useState, useRef } from "react";
import { CopyRight } from "../../components/Copyright";
import {server} from "../../utils";
import { httpGet, httpPost } from "../../servicios";
import UserAnai from "../../models/UserAnai";
import Info from "../../components/Info/Info";

export default function RecuperacionClaveNue() {

  const {state} = useStore();
  const [loading, setLoading] = useState(false);
  const [emailEst, setEmailEst] = useState("");
  const [nomEst, setNomEst] = useState("");
  const [pass, setPass] = useState("");
  const [userName, setUserName] = useState("");
  const [emailTeams, setEmailTeams] = useState("");
  const [notif, setNotif] = useState(false);
  const [mensajeError, setMensajeError] = useState("");
  let refe = useRef();
 


  async function verificarUsuario(event){
    event.preventDefault();
    setLoading(true)
    httpGet(`${server}webuser/?codigo=${emailTeams.trim()}`,state.token)
      .then(res=>{
        setNomEst(res.data.nom_est)
        setPass(res.data.password)
        if(res.data.username){
          setUserName(res.data.username)
        }else{
          setNotif(true);
          setMensajeError("Numero de Identificación Incorrecto!");
        }
        setLoading(false)
      })
      .catch(err=>{
        setLoading(false)
        console.log("Error en recuperar datos:",err)
      });
  }

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }

  async function sendEmail(event){
    event.preventDefault();
    setLoading(true);
    const userAnai = new UserAnai(
      userName,
      pass,
      nomEst,
      emailEst
    );
    httpPost(`${server}sendmail/webuser/`,userAnai,state.token)
    .then((res)=>{
      setLoading(false);
      setNotif(true);
      setMensajeError("Correo Electronico Enviado! Gracias");
      
    })
    .catch(err=>{
      setLoading(false);
      setNotif(true);
      setMensajeError("Error en enviar correo...!");
    });

    
  }
  return (
    <div className="flex">
      <main className="content">
        <Info 
          ref={refe} 
          visible={notif} 
          titulo={`Recuperación de contraseña`} 
          mensaje={mensajeError} 
          cerrar={setNotif}
        />
        <Container component="main" maxWidth="xs">
          <div className="content-login">
            <Typography component="h1" variant="h5">
              Recuperación de Contraseña
            </Typography>
          </div>
          {userName==="" ? 
            <form className="form-login" noValidate onSubmit={verificarUsuario}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Identificacion , cedula ruc o pasaporte"
                name="email"
                autoComplete="email"
                value={emailTeams}
                onChange={(e) => setEmailTeams(e.target.value)}
                autoFocus
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className="button-login"
              >
                Continuar
              </Button>
            </form> 
          :
            <form className="form-login" noValidate onSubmit={sendEmail}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="nomest"
                label="Estudiante"
                name="nomest"
                autoComplete="nomest"
                value={nomEst}
                //onChange={(e) => setNomEst(e.target.value)}
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Correo Electrónico del Representante"
                name="email"
                autoComplete="email"
                value={emailEst}
                onChange={(e) => setEmailEst(e.target.value)}
                autoFocus
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className="button-login"
              >
                Enviar correo
              </Button>

              <Button
                type="button"
                fullWidth
                variant="contained"
                color="secondary"
                className="button-login"
                onClick={() => window.location="/" }
              >
                Volver al Inicio
              </Button>
            </form>   
          }
          {loading ? 
            <Grid container justify="center">
              <CircularProgress />
            </Grid>
          :
          <div></div>
          }

           
          
          <CopyRight />
        </Container>

      </main>
    </div>
  
  );
};
