import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import { useStore } from "../../../context";
import { navigate } from "@reach/router";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Badge from "@material-ui/core/Badge";

//-------------------------------------------------------------------------
// Parte Izquierda de la cabecera de matricula
//-------------------------------------------------------------------------
const MenuHeader = () => {
  const { state, dispatch } = useStore();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  return (
    <>
      <IconButton style={{ color: "#fff" }} onClick={() => navigate("/matricula/cartMat")}>
        <Badge badgeContent={ state.cartMat.length} color="secondary">
          <ShoppingCartIcon />
        </Badge>
      </IconButton>
      
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        style={{ color: "#fff" }}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={openMenu}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          width: "20ch",
        }}
      >
      <MenuItem
          onClick={() => {
            setAnchorEl(null);
            dispatch({ type: "REMOVE_TOKEN" });
            dispatch({ type: "RESET_CART_MAT" });
            dispatch({ type: "REMOVE_LOGIN"});
            dispatch({ type: "REMOVE_PROCESS_URL" });
            dispatch({ type: "REMOVE_USER" });
            dispatch({ type: "REMOVE_ID_PAYMENT" });
            dispatch({ type: "REMOVE_OPCIONES" });
            dispatch({ type: "REMOVE_KEYS" });
            navigate("/");
          }}
        >
          Cerrar Sesión
        </MenuItem>
      </Menu>
    </>
  );
};

export default MenuHeader;
