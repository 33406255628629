export default class LogMessage{
    constructor(
        id,
        file,
        message,
        fecha,
        idSobre
    ){
        this.id = id;
        this.file = file;
        this.message = message;
        this.fecha = fecha;
        this.idSobre = idSobre;
    }
}