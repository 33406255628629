import React, { useEffect } from "react";
import { Box, Button, MenuItem, TextField, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import { httpGet, httSaveImg} from "../../servicios";
import { urlGetInscripciones, urlInsertImage } from "../../utils";
import { useStore } from "../../context";
const _ = require ("lodash");
var moment = require('moment');

//-------------------------------------------------------------------------
//Estilos de la pagina de Carga de Archivos
//-------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
    root: {
      //flexGrow: 1,
      backgroundColor:theme.palette.background.paper,
      width:"100%",
     
    },
    title:{
        fontWeight:"bold"
    },
    section:{
        marginBottom:10,
        marginTop:20
    },
    combo:{
        width:"80%"
    }
}))

export default function FileUpload (){
    const classes = useStyles();
    const { state } = useStore();
    const [archivos, setArchivos] = React.useState(null);
    var [cmbAspirantes, setCombo] = React.useState([])
    var [aspirante, setAspirante] = React.useState("0")

    const getDescripcion = (id)=>{
        const {grados} = state;
        var grado = _.find(grados,{'value':id});
        return grado.label;
    
    }

    const subirArchivos = (e)=>{
        setArchivos(e)
    }

    const changeAspirante = (event) => {
        setAspirante(event.target.value);
    };

    //-------------------------------------------------------------------------
    // Carga de los aspirantes en base al usuario que logueo en inscripciones
    //-------------------------------------------------------------------------

    useEffect(() => {
        httpGet(urlGetInscripciones+sessionStorage.getItem("username"),state.token).then((data)=>{
            if(data.cod === 0){
                if(data.length===0){
                    alert("Sin aspirantes registrados.")
                }
                let aspirantes = data.data;
                let _cmbAspirantes = [{"value":"0","label":"Seleccione un aspirante"}];
                aspirantes.forEach((item)=>{
                    _cmbAspirantes.push({"value":item.numDoc, "label":item.apellidos +' '+ item.nombres +" - Grado aplica "+ getDescripcion(item.gradoAplica)})
                })
                setCombo(_cmbAspirantes)
            }
        });
    }, [])
    
    //-------------------------------------------------------------------------
    // Guarda las imagenes como FormData con el web Service
    //-------------------------------------------------------------------------

    const insertarArchivos = async ()=>{
        let usuario = sessionStorage.getItem("username");
        let _hoy = moment();
        _hoy =_hoy.format("YYYY-MM-DD HH:mm:ss");
        let hoy = new Date(_hoy);
        if(aspirante === "0"){
            return alert("¡Seleccione al aspirante!")
        }
        if(archivos === null){
            return alert ("¡Seleccione los archivos a cargar!")
        }
        var formData = new FormData();
        if(archivos.lenght <= 0 || archivos.length > 2){
            return alert("¡Debe seleccionar minimo un archivo para cargar!")
        }
        formData.append("id", null);
        formData.append("aspirante", aspirante);
        formData.append("foto1", archivos[0]);
        formData.append("foto2", archivos[1] === undefined ? archivos[0]:archivos[1]);
        formData.append("usuarioCrea", usuario);
        formData.append("fechaCrea", hoy);
        formData.append("estado", "A");

        let _envio = await httSaveImg(urlInsertImage,formData, state.token );
        if(_envio.cod === 0){
            alert("¡Imagenes cargadas exitosamente!")
        }else{
            return alert("¡Error en la carga de las imagenes!")
        }
    }

    //-------------------------------------------------------------------------
    // JSX que renderiza la pagina
    //-------------------------------------------------------------------------

    return(
        <Box>
            <Typography variant="h6">Carga de archivos.</Typography>
            <br/>
            <Typography>
                1.-Seleccione uno de los aspirante inscrito. 
            </Typography>
            <Typography style={{marginTop:10}}>
                2.-Cargue una copia de la libreta del año que está cursando
                o que ya finalizó (no es un requisito para Educación inicial 2 y 1ro de Básica, se solicitará solo si la 
                esta cursando o ya finalizaron).
            </Typography>

            <Typography style={{marginTop:10}}>
                3.-Copia de la Inscripción de nacimiento o copia de la cédula de identidad del aspirante.
            </Typography>
            <Box className={classes.section}>
                    <Typography component={'div'} className={classes.title}>Seleccione un aspirante:(*)</Typography>
                    <Box>
                    <TextField
                    id="aspirante"
                    name="aspirante"
                    select
                    className={classes.combo}
                    label=""
                    value={aspirante}
                    onChange={changeAspirante}
                    helperText="Seleccione un aspirante para asignarle los archivos solicitados"
                    >
                    {cmbAspirantes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                        {option.label}
                        </MenuItem>
                    ))}
                    </TextField>
                    </Box>
                </Box>
            <Box className={classes.section}>
                <input type="file" name ="files" multiple accept="image/png, image/jpeg" onChange={(e)=>subirArchivos(e.target.files)} />
                <Button variant="contained" color="primary" onClick={()=>{insertarArchivos()}}>Subir archivo</Button>    
            </Box>
   
       </Box>
    )
}