export const sexo =[{value:"M", label:"Masculino"},{value:"F", label:"Femenino"}]

export const listJornada = [{value:"M", label:"Matutina"}, {value:"V", label:"Vespertina"}]
 export const listGrados =[
     //{value:"", label :"Seleccione un grado"},
     {value:"K3", label:"Prekinder 3 años"},
     {value:"K4", label:"Kinder 4 años"},
     {value:"PB", label:"Primero de básica"},
     {value:"SB", label:"Segundo de Básica"},
     {value:"TB", label:"Tercero de Básica"},
     {value:"CB", label:"Cuarto de Básica"},
     {value:"QB", label:"Quinto de Básica"},
     {value:"SA", label:"Sexto de Básica"},
     {value:"SP", label:"Séptimo de Básica"},
     {value:"OB", label:"Ocatvo de Básica"},
     {value:"NB", label:"Noveno de Básica"},
     {value:"DB", label:"Décimo de básica"},
     {value:"PT", label:"Primero de Bachillerato"},
     {value:"ST", label:"Segundo de Bachillerato"},
     {value:"TT", label:"Tercero de Bachillerato"}
 ]
export const listSiNo = [{value:"S", label:"SI"},
{value:"N", label:"NO"}]