import React, {useState} from 'react';
//import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { Button, CircularProgress, FormControl, FormControlLabel, FormLabel, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, Radio, RadioGroup } from '@material-ui/core';
import { navigate } from '@reach/router';
//import { useStore } from "../../context";
import { CopyRight } from '../../components/Copyright';
import { httpPost, httpGet } from '../../servicios';
import {urlRegistro, urlRegistroExisteNew, urlRegistroExisteAnai, verificarCedula, urlAuth, urlGetGrados, urlOpciones} from '../../utils';
//import { TextMaskCedula, TextMaskPas, TextMaskRuc } from '../../components/Masked';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Usuario from '../../models/Usuarios';
import Info from "../../components/Info/Info";
import { useStore } from '../../context';

var moment = require('moment');

const useStyles = makeStyles((theme) => ({
    root: {
      //flexGrow: 1,
      backgroundColor:theme.palette.background.paper,
      width:"100%",
     
    },
    title:{
        fontWeight:"bold"
    },
    note:{
        fontSize:"12px"
    },
    encabezados:{ flexGrow:1, flexDirection:"column", marginTop:20, marginLeft:10},
    botones:{
        flex:1,
        flexDirection:"row",
        textAlign:"center",
        justifyContent:"space-between",
        alignContent:"flex-end",
        textJustify:""
    },
    toolBar:{
      flexDirection:"row",
      justifyContent:"space-between",
  
    },
    section:{ width:"150%", marginTop:20, marginBottom:10, marginLeft:20},
    combo:{marginRight:15, width:"50%"},
    textBox:{marginRight:15, width:"40%"},
    margin: {
        margin: theme.spacing(1),
      },
  
  }));

export default function RegistroNuevo() {
    const { dispatch } = useStore();
    const refCedula = React.useRef();
    const refRuc = React.useRef();
    const refPasaporte = React.useRef();
    const [notif,setNotif] = useState();
    let refe = React.useRef();
    const [mensajeNotificacion, setMensajeNotificacion] = useState();
    
    const refNombres = React.useRef();
    const refApellidos = React.useRef();
    const refClave = React.useRef();
    const refClave2 = React.useRef();
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    //const [tipDoc, setTipDoc] = React.useState('');
    const [usuario, setUsuario] = React.useState({
      tipoDoc:"C",
      identificacion:"",
      nombres:"",
      apellidos:"",
      clave:"",
      clave2:""
  })
    const [config, setConfig] = React.useState({
    password: '',
    clave: false,
    clave2:false,
  })
    const [error, setError] = React.useState({
        errorIden:false,
        errorNombres:false,
        errorApellidos:false,
        errorClave:false,
        errorClave2:false,
        errorPasaporte:false,
        errorRuc:false
    })

    const changeData = (event) => {
    
        //setTipDoc(event.target.value);
        setUsuario({...usuario, [event.currentTarget.name]:event.currentTarget.value});
        /*setHelperText(' ');
        setError(false);*/
      };

    const validaControles = () =>{
    try {
        if(usuario.tipoDoc === "R"){
            return (
                <TextField
                inputRef={refRuc}
                label="Número de RUC"
                value={usuario.identificacion}
                onChange={changeData}
                name="identificacion"
                id="identificacion"
                variant="outlined"
                onBlur={()=>{
                    if(usuario.identificacion.trim() === ""){
                        validar("errorRuc",true);
                        //refRuc.current.focus();
                        //return alert ("¡Debe ingresar su número de RUC!");
                    }else{
                           validar("errorRuc",false); 
                      }   
            }}
                error={error.errorRuc}
              /> 
            )
           
        }else if(usuario.tipoDoc === "P"){
            return(
                <TextField
                inputRef={refPasaporte}
                label="Número de Pasaporte"
                value={usuario.identificacion}
                onChange={changeData}
                name="identificacion"
                id="identificacion"
                variant="outlined"
                inputProps={{maxLength :13}}
                
                /* InputProps={{
                  inputComponent: TextMaskPas
                }} */
                error={error.errorPasaporte}
                onBlur={()=>{
                    if(usuario.tipoDoc === "P"){
                      //alert(usuario.identificacion.trim().length)
                      if(usuario.identificacion.trim() === ""){
                          validar("errorPasaporte",true);
                          //refPasaporte.current.focus();
                          //return alert ("¡Ingrese el número de pasaporte!");
                      }else{
                          validar("errorPasaporte",false);
                        }
                  }  
              }}
                
              /> 
            )
            
        }else{
            return(
                <TextField
                inputRef={refCedula}
                label="Número de identificación"
                value={usuario.identificacion}
                onChange={changeData}
                name="identificacion"
                id="identificacion"
                variant="outlined"
                onBlur={()=>{
                    if(usuario.tipoDoc === "C"){
                        if(usuario.identificacion.trim().length > 10 || usuario.identificacion.trim().length < 10){
                          validar("errorIden",true);
                          //refCedula.current.focus();
                          //return alert ("¡Número de cédula tiene que contener 10 dígitos.");
                      }else{
                        let verifica = verificarCedula(usuario.identificacion.trim());
                        if(!verifica){
                            validar("errorIden",true);
                            //refCedula.current.focus();
                            //return alert("¡Número de cédula inválido!");
                           
                        }else{
                            validar("errorIden",false);
                        }
                         
                        }
                  }   
              }}
              error={error.errorIden}
              />
            )
        }
             
          
                
    } catch (error) {
        
    }
  }

  

  const validar=(campo, value)=>{
    setError({...error, [campo]:value})
  }
  const handleClickShowPassword = (event) => {
     
    setConfig({ ...config, [event]: !config[event] });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const  save = async () => {
    const {tipoDoc, identificacion, nombres, apellidos, clave, clave2} = usuario;
    let _hoy = moment();
    _hoy =_hoy.format("YYYY-MM-DD HH:mm:ss");
    let hoy = new Date(_hoy);

    if(tipoDoc === ""){
        setNotif(true)
        setMensajeNotificacion("¡Seleccione el tipo de documento!")
        //return alert("¡Seleccione el tipo de documento!")
    }
    if(tipoDoc === "C"){
        if(identificacion === ""){
            validar("errorIden",true);
            refCedula.current.focus();
            setNotif(true)
            return setMensajeNotificacion("¡Debe ingresar el número de identificación!")
            //return alert("¡Debe ingresar el número de identificación!")
        }
        if(identificacion.trim().length > 10 || identificacion.trim().length < 10){
            validar("errorIden",true);
            refCedula.current.focus();
            setNotif(true)
            return setMensajeNotificacion("¡Número de cédula tiene que contener 10 dígitos!.")
            //return alert ("¡Número de cédula tiene que contener 10 dígitos!."); 
        }
        if(!verificarCedula(identificacion.trim())){
            validar("errorRuc", true);
            //refRuc.current.focus();
            setNotif(true)
            return setMensajeNotificacion("¡Número de cédula invalido!.")
        }
    }else if(tipoDoc === "R"){
        if(identificacion === ""){
            validar("errorRuc",true);
            refRuc.current.focus();
            setNotif(true)
            return setMensajeNotificacion("¡Debe ingresar el número de RUC!")
            //return alert("¡Debe ingresar el número de RUC!")
        }
        if(identificacion.trim().length > 13 || identificacion.trim().length < 13){
            validar("errorRuc", true);
            refRuc.current.focus();
            setNotif(true)
            return setMensajeNotificacion("¡Número de RUC tiene que contener 13 dígitos!.")
            //return alert("¡Número de RUC tiene que contener 13 dígitos!.")
        }
    }else if(tipoDoc === "P"){
        if(identificacion === ""){
            validar("errorPasaporte",true);
            refPasaporte.current.focus();
            setNotif(true)
            return setMensajeNotificacion("¡Debe ingresar el número de pasaporte!")
            //return alert("¡Debe ingresar el número de pasaporte!")
        }
    }
    
    if(nombres === ""){
        setError("errorNombres", true);
        refNombres.current.focus();
        setNotif(true)
        return setMensajeNotificacion("¡Debe ingresar los nombres!")
        //return alert("¡Debe ingresar los nombres!")
    }
    if(apellidos === ""){
        setError("errorApellidos", true);
        refApellidos.current.focus();
        setNotif(true)
        return setMensajeNotificacion("¡Debe ingresar sus apellidos!")
        //return alert("¡Debe ingresar sus apellidos")
    }
    if(clave === ""){
        setError("errorClave", true);
        refClave.current.focus();
        setNotif(true)
        return setMensajeNotificacion("¡Debe de ingresar la clave!")
        //return alert("¡Debe de ingresar la clave!")
    }
    if(clave2 !== clave){
        setError("errorClave", true);
        refClave.current.focus();
        setNotif(true)
        return setMensajeNotificacion("¡Las claves no son iguales!")
        //return alert("¡Las claves no son iguales!")
    }
    setLoading(true);
    
    let objUsuario = new Usuario(tipoDoc, identificacion, nombres.toUpperCase(), apellidos.toUpperCase(),clave, "A", hoy, null, "USER")

    /*const data = {
        "user":objUsuario,
    };*/

    //Verifica si ya esta registrado como usuario Anai
    
    let existenciaUsuarioAnai = await httpGet(urlRegistroExisteAnai+identificacion);
    if(existenciaUsuarioAnai.data.existe){
        setNotif(true)
        setMensajeNotificacion("Ya existe usuario Anai");
        setLoading(false);
        //alert("Ya existe usuario Anai");
        //navigate("/")
    }else{
        //Verifica si ya esta registrado como usuario nuevo
        let existenciaUsuarioNew = await httpGet(urlRegistroExisteNew+identificacion);
        if(existenciaUsuarioNew.data.existe){
            setNotif(true)
            setMensajeNotificacion("Ya existe usuario Nuevo")
            setLoading(false); 
            //alert("Ya existe usuario Nuevo");
            //navigate("/")
        }else{

            //Registrar nuevo usuario
            let registro = await httpPost(urlRegistro,objUsuario);
            setLoading(false);
            if(registro.cod === 0){
                setNotif(true);
                setMensajeNotificacion("¡Registro exitoso!");
                //alert("¡Registro exitoso!");
                setLoading(false);

                const data = {
                    "username":"A"+objUsuario.identificacion,
                    "password":objUsuario.clave
                  };
                  const options = {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data)
                  };

                  fetch(`${urlAuth}auth/login`,options)
                    .then((res) => {     
                        return res.json()
                    })
                    .then(async(data) => {
                        let _opciones = await httpGet(urlOpciones,data.token);
                        let _grados   = await httpGet(urlGetGrados,data.token);
                        if(data.token){
                        dispatch({type:"SET_OPCIONES", payload:_opciones.data});
                        dispatch({type:"SET_GRADOS",   payload:_grados.data})
                        dispatch({ type: "SET_TOKEN", payload: data });
                        // navigate("/pension/list");
                        navigate("/inscripcion/list");
                        }else{
                        setNotif(true);
                        }
                    })
                    .catch((err) => {
                        setNotif(true);
                        console.log("Error de promesa:",err);
                    })


                // setTimeout(()=>{
                //     navigate("/");
                // },2000)
            }else{
                setNotif(true)
                setMensajeNotificacion("¡Hubo un error, vuelva a intentarlo!")
                setLoading(false);
                //alert("¡Hubo un error, vuelva a intentarlo!")
            }
        }
    }
        

    

  }

  if (loading) {
    return (
      <Grid container justify="center" style={{marginTop:70}}>
        <CircularProgress />
      </Grid>
    );
  }

    return(
        <Paper className="container-table">
            <Info 
                ref={refe} 
                visible={notif} 
                titulo={`Notificacion Registro`} 
                mensaje={mensajeNotificacion} 
                cerrar={setNotif}
            />
        <Grid>
        <FormControl component="fieldset" className={classes.encabezados}>
        <FormLabel component="legend">Ingrese los datos para su registro para poder hacer inscripciones 2.</FormLabel>
        
        <Box className={classes.section}>
            <Typography component={'div'} className={classes.title}>Documento de identidad.(*)</Typography>
            <RadioGroup aria-label="gender" name="tipoDoc" value={usuario.tipoDoc} onChange={changeData} defaultValue="C" style={{flexDirection:"row"}}>
                <FormControlLabel value="C" control={<Radio color="primary"/>} label="Cédula" />
                <FormControlLabel value="P" control={<Radio color="primary" />} label="Pasaporte" />
                <FormControlLabel value="R" control={<Radio color="primary" />} label="RUC" />
            </RadioGroup>
        </Box>

    <Box className={classes.section}>
       <FormControl variant="outlined">
        {/* <InputLabel htmlFor="component-outlined">Número de identificación</InputLabel> */}
        {validaControles()}
          <br/>
           <FormLabel component="legend">Su número de identificación será su nombre de usuario.</FormLabel>
            </FormControl>
    </Box>

    <Box className={classes.section}>
        <Typography component={'div'} className={classes.title}>Nombres y Apellidos:(*)</Typography>
        <br/>
        <Box>
            <TextField 
            inputRef={refNombres}
            className={classes.textBox} 
            id="nombre" 
            name = "nombres" 
            label="Nombres" 
            variant="outlined" 
            value={usuario.nombres} 
            onChange={changeData}
            onBlur={()=>{
                    
                        if(usuario.nombres.trim() === ""){
                            validar("errorNombres",true);
                            //refNombres.current.focus();
                        }else{
                          
                              validar("errorNombres",false);
                           
                          }   
                }}
            error={error.errorNombres}
            />
            <TextField  inputRef={refApellidos}
                        className={classes.textBox} 
                        id="apellidos" 
                        name="apellidos" 
                        label="Apellidos" 
                        variant="outlined" 
                        value={usuario.apellidos} 
                        onChange={changeData}
                        onBlur={()=>{
                            if(usuario.apellidos.trim() === ""){
                                validar("errorApellidos",true);
                                //refApellidos.current.focus();
                                //return alert ("¡Debe ingresar los apellidos!");
                            }else{
                                  validar("errorApellidos",false);
                              }   
                        }}
                    error={error.errorApellidos}/>
        </Box>
    </Box>

    <Box className={classes.section}>
        <Typography component={'div'} className={classes.title}>Contraseña:(*)</Typography>
        <br/>
        <Box>
            {/*<TextField required className={classes.textBox} id="clave" name = "clave" label="Contraseña" variant="outlined" value={usuario.clave} onChange={changeData}/>
            <TextField className={classes.textBox} id="clave2" name="clave2" label="Validar contraseña" variant="outlined" value={usuario.clave2} onChange={changeData}/>*/}

            <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                <OutlinedInput
                    inputRef={refClave}
                    id="clave"
                    name="clave"
                    type={config.clave ? 'text' : 'password'}
                    value={usuario.clave}
                    onChange={changeData}
                    error={error.errorClave}
                    onBlur={()=>{
                        if(usuario.clave.trim() === ""){
                            validar("errorClave",true);
                            //refClave.current.focus();
                            //return alert ("¡Debe ingresar su clave!");
                        }else{
                              validar("errorClave",false);
                          }   
                    }}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={()=>{handleClickShowPassword("clave")}}
                        edge="end"
                        >
                        {config.clave ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }
                    labelWidth={100}
                />
        </FormControl>

        <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Validar contraseña</InputLabel>
          <OutlinedInput
            inputRef={refClave2}
            id="clave2"
            name="clave2"
            type={config.clave2 ? 'text' : 'password'}
            value={usuario.clave2}
            onChange={changeData}
            error={error.errorClave2}
                    onBlur={()=>{
                        if(usuario.clave2.trim() === ""){
                            validar("errorClave2",true);
                            //refClave2.current.focus();
                            //return alert ("¡Debe confirmar su clave!");
                        }else if(usuario.clave.trim() !== usuario.clave2.trim()){
                            validar("errorClave2",true);
                            //refClave2.current.focus();
                            //return alert ("¡Sus claves no son iguales!");
                        }
                        else{
                              validar("errorClave2",false);
                          }   
                    }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>{handleClickShowPassword("clave2")}}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {config.clave2 ? <VisibilityOff /> : <Visibility /> }
                </IconButton>
              </InputAdornment>
            }
            labelWidth={130}
          />
        </FormControl>
 
 
        </Box>
    </Box>
    <br/>
    <Box className={classes.botones}>
    <Button   style={{marginLeft:20}}
                            variant="contained"
                            //className="button-login"
                            color="primary" onClick={()=>{navigate("/")}}>Atras </Button>

                  <Button   style={{marginLeft:20}}
                            variant="contained"
                            //className="button-login"
                            color="primary" onClick={()=>{save()}}>Registrarse </Button>
      </Box>
<CopyRight/>
    </FormControl>
    </Grid>
    </Paper>
    )
    

}