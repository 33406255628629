export default class PagoCab{
    constructor(
        idSobre,
        moneda,
        valorTotal,
        formaPago,
        nomCli,
        fechaProceso,
        horaProceso,
        condProceso,
        mensajeProceso,
        emailPayer,
        docPayer,
        referencia,
        detalle
    ){
        this.idSobre = idSobre;
        this.moneda = moneda;
        this.valorTotal = valorTotal;
        this.formaPago = formaPago;
        this.nomCli = nomCli;
        this.fechaProceso = fechaProceso;
        this.horaProceso = horaProceso;
        this.condProceso = condProceso;
        this.mensajeProceso = mensajeProceso;
        this.emailPayer = emailPayer;
        this.docPayer = docPayer;
        this.referencia = referencia;
        this.detalle = detalle;

    }
}