import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DateFnsUtils from '@date-io/date-fns';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Inscripcion from "../../models/Inscripcion";
import Representante from "../../models/Representante";
import {  MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Button, FormControl, FormControlLabel, FormLabel, Paper, Radio, RadioGroup } from '@material-ui/core';
import {sexo, listJornada, listSiNo} from "../../utils/opciones.js";
import { navigate } from '@reach/router';
import { useStore } from "../../context";
import Pagador from '../../models/Pagador';
import { verificarCedula} from '../../utils';
import TagManager from 'react-gtm-module';

var moment = require('moment');

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div p={3}>
          <Typography component={'div'}>{children}</Typography>
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};



function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const useStyles = makeStyles((theme) => ({
  root: {
    //flexGrow: 1,
    backgroundColor:theme.palette.background.paper,
    width:"100%",
   
  },
  title:{
      fontWeight:"bold"
  },
  titleTasa:{
    fontWeight:"bold",
    color:"gray",
  },
  note:{
      fontSize:"12px",
  },
  encabezados:{ flexGrow:1, flexDirection:"column", marginTop:20, marginLeft:10},
  botones:{
      flex:1,
      flexDirection:"row",
      textAlign:"center",
      justifyContent:"space-between",
      alignContent:"center",
      textJustify:"",
      marginBottom:20
  },
  toolBar:{
    flexDirection:"row",
    justifyContent:"space-between",

  },
  section:{ width:"160%", marginTop:20, marginBottom:10, marginLeft:20},
  combo:{marginRight:15, width:"50%"},
  textBox:{marginRight:15, width:"30%"},

}));


export default function Inscripciones() {
  const { state, dispatch } = useStore();
  const classes = useStyles();
  const [paso, setSteps] = React.useState(0);
  const [tipoDocPag, setValueTip] = React.useState("CI");
  const [tipDoc, setValue2] = React.useState('C');
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [institucionAntes, setInstitucion] = React.useState("");
  const [sex, setSex] = React.useState('M');
  const [jornada, setJornada] = React.useState('M');
  const [grado, setGrado] = React.useState(sessionStorage.getItem("grados") ? sessionStorage.getItem("grados")[0].value : 'Default');
  const [estudio, setEstudio] = React.useState('S'); //si estudio en anai
  const [hijoEx, setHijoEx] = React.useState('S'); //si es hijo de ex alumno
  const [personasVive, setPersonas] = React.useState('');
  const [celular, setCelular] = React.useState('');
  const [convencional, setConvencional] = React.useState('');
  const [mailM, setMail2] = React.useState('');
  const [tipDocP, setTipoDocP] = React.useState('C');
  const [cedulap, setCedulaP] = React.useState({ textmask: '', numberformat: '1320', });
  const [nombresM, setNombresM] = React.useState('');
  const [apellidosM, setApellidosM] = React.useState('');
  const [nombresP, setNombresP] = React.useState('');
  const [apellidosP, setApellidosP] = React.useState('');
  const [tipDocM, setTipoDocM] = React.useState('C');
  const [cedulaM, setCedulaM] = React.useState({
    textmask: '',
    numberformat: '1320',
  });
  //Pagador
  const [cedulaPa, setCedulaPa] = React.useState({
    textmask: '',
    numberformat: '1320',
  });
  const [cedulaE, setCedulaE] = React.useState({
    textmask: '',
    numberformat: '1320',
  });

  const refCedulaE = React.useRef();
  const refCedulaPa = React.useRef();
  const refCedulaP = React.useRef();
  const refCedulaM = React.useRef();
  const [errorCedulaE, setErrorCedulaE] = React.useState(false);
  const [errorCedulaP, setErrorCedulaP] = React.useState(false);
  const [errorCedulaM, setErrorCedulaM] = React.useState(false);
  const [errorCedulaPa, setErrorCedulaPa] = React.useState(false);
  const [nombresA, setNombresE] = React.useState('');
  const [apellidosA, setApelE] = React.useState('');
  const [nombresPa, setNombresPa] = React.useState('');
  const [apellidosPa, setApelPa] = React.useState('');
  const [mailPa, setMailPa] = React.useState('');
  const [direccionPa, setDireccionPa] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const[mail, setMail] = React.useState("");
  
  const [state2, setState] = React.useState({
    checkedA: false,
  });

  const handleTipDocPag = (event) => {
    setValueTip(event.target.value);
  };

  const changeTipoDocM=(event)=>{
    setTipoDocM(event.target.value)
  }

  const changeTipoDocP=(event)=>{
    setTipoDocP(event.target.value)
  }

  const validarCedulaE =(valor)=>{
    setErrorCedulaE(valor)

  }
  const validarCedulaP =(valor)=>{
    setErrorCedulaP(valor)

  }
  const validarCedulaM =(valor)=>{
    setErrorCedulaM(valor)
  }
  const validarCedulaPa =(valor)=>{
    setErrorCedulaPa(valor)
  }

  const changeNombresP=(event )=>{
      setNombresP(event.target.value)
  }
  const changeApellidosP = (event)=>{
    setApellidosP(event.target.value)
  }

  const changeApellidosM = (event)=>{
    setApellidosM(event.target.value)
  }

  const changeNombresM = (event)=>{
    setNombresM(event.target.value)
  }

  const changeCelular = (event)=>{
      setCelular(event.target.value)
  }

  const changeConvencional =(event)=>{
      setConvencional(event.target.value)
  }
  const jornadahandleChange = (event) => {
    setJornada(event.target.value);
  };

  const changeCedE =(event)=>{
      setCedulaE ({
        ...cedulaE,
        [event.target.name]: event.target.value,
      })
  }
  const changeCedPa = (event)=>{
    setCedulaPa ({
      ...cedulaPa,
      [event.target.name]: event.target.value,
    })
  }

  const checkChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const personasChange =(event)=>{
    setPersonas(event.target.value)
  }

  const changeCedM =(event)=>{
      setCedulaM({
        ...cedulaM,
        [event.target.name]: event.target.value,
      })
  }
  const hijoExChange =(event)=>{
      setHijoEx(event.target.value)
  }

  const institucionChange = (event)=>{
    setInstitucion(event.target.value)
  }
  const gradoChange = (event)=>{
    setGrado(event.target.value)
  }
  const changeMail=(event)=>{
      setMail(event.target.value)
  }

  const onChangeNomE =(event)=>{
      setNombresE(event.target.value)
  }

  const onChangeApelE =(event)=>{
      setApelE(event.target.value)
  }

  const onChangeNomPa =(event)=>{
    setNombresPa(event.target.value)
  }

  const onChangeApelPa =(event)=>{
    setApelPa(event.target.value)
  }

  const changeMailPa = (event)=> {
    setMailPa(event.target.value)
  }

  const changeDireccionPa = (event)=> {
    setDireccionPa(event.target.value)
  }

  const changeCedP = (event)=>{
      setCedulaP({
        ...cedulap,
        [event.target.name]: event.target.value,
      })
  }

  const changeMail2=(event)=>{
      setMail2(event.target.value)
  }
  const sexhandleChange = (event) => {
    setSex(event.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const estudiosAntChange = (event) =>{
    setEstudio(event.target.value)
  }

  const handleTipDoc = (event) => {
    setValue2(event.target.value);
  };

  const changeSteps = (event, newValue) => {
    setSteps(newValue);
  };

  const validarData=async()=>{
    try {
      let validate = false;
      let usuario = sessionStorage.getItem("username");
      let _hoy = moment();
      _hoy =_hoy.format("YYYY-MM-DD HH:mm:ss");
      let hoy = new Date(_hoy);
      //Validaciones de campos
      if(cedulaE === ""){
            return alert('¡Debe ingresar el número de identificación del aspirante!')
      }else if(nombresA === ""){
          return alert("¡Debe ingresar los nombres del aspirante!");
          //return;
      }else if(apellidosA === ""){
        return alert("¡Debe ingresar los apellidos del aspirante!");
      }else if(estudio === "N" && institucionAntes === ""){
            return alert("¡Debe indicar su institución anterior!")
      }else if(selectedDate === null ){
        return alert("¡Debe seleccionar una fecha!")
      }
      else if(personasVive === ""){
          return alert("¡Debe ingresar a las personas con quién vive!")
      }else if(state2.checkedA === false){
            return alert("¡Debe indicar si aspirante tiene calificación A o B!")
      }else if(cedulap === ""){
          return alert("¡Debe ingresar el número de identificación del Padre!")
      }else if(cedulaM === ""){
        return alert("¡Debe ingresar el número de identificación de la madre!")
      }else if(nombresM === ""){
            return alert("¡Debe ingresar los nombres de la madre!");
      }else if(apellidosM === ""){
          return alert ("¡Debe ingresar los apellidos de la madre!")
      }else if(nombresP === ""){
          return alert ("¡Debe ingresar los nombres del padre!");
      }else if(apellidosP === ""){
        return alert ("¡Debe ingresar los apellidos del padre")
      }else if (mail === ""){
          return alert ("¡Debe ingresar el correo electrónico del padre!")
      }else if(mailM === ""){
          return alert ("¡Debe ingresar el correo electrónico de la madre!")
      }else if(celular === ""){
          return alert ("¡Debe ingresar un número de celular!")
      }else if(convencional === ""){
          return alert ("¡Debe ingresar un número convencional!")
      }else   if(cedulaPa === ""){
        return alert("¡Debe ingresar el número de indentificación de quién realizará el pago!");
      }else if(nombresPa === ""){
        return alert("¡Debe ingresar los nombres de quién realizará el pago!")
      }else if(apellidosPa === ""){
        return alert("¡Debe ingresar los apellidos de quién realizará el pago!")
      }else if(mailPa === ""){
        return alert ("¡Debe ingresar el correo electrónico de la persona que realizará el pago!")
      }else if(direccionPa === ""){
        return alert ("¡Debe ingresar la dirección de la persona que realizará el pago!")
      }else if(cedulaE.length > 10){
        return alert("¡Número de dígitos en la identificación del aspirante mayor a 10!")
      }else if(cedulaPa.length > 10){
        return alert("¡Número de dígitos en la identificación del pagador mayor a 10!")
      }
      else if(cedulaM.length > 10){
        return alert("¡Número de dígitos en la identificación de l madre mayor a 10!")
      }
      else if(cedulap.length > 10){
        return alert("¡Número de dígitos en la identificación del padre mayor a 10!")
      }else if(cedulap===cedulaM){
        return alert("¡Cedulas de Padre y Madre deben ser distintas!")
      }
      
      let arrayRepresentantes = [];
      
      let objPago = new Pagador (tipoDocPag,cedulaPa.textmask, nombresPa, apellidosPa, mailPa, "",direccionPa);
      //let objRepresentante = new Representante(null, "C",cedulap, nombresP, apellidosP, mail, celular, convencional,usuario , hoy, null,null,"A");
      let objRepresentante = new Representante(null,tipDocP,cedulap.textmask, nombresP.toUpperCase(), apellidosP.toUpperCase(),mail, celular, convencional,usuario,hoy,null,null,"A","P")
      //let objRepresentante2 = new Representante(null, "C",cedulaM, nombresM, apellidosM, mailM, celular, convencional, usuario, hoy, null,null,"A")
      let objRepresentante2 = new Representante(null,tipDocM, cedulaM.textmask, nombresM.toUpperCase(), apellidosM.toUpperCase(), mailM, celular, convencional,usuario,hoy,null,null,"A","M")
      arrayRepresentantes.push(objRepresentante);
      arrayRepresentantes.push(objRepresentante2);
      
      //let objInscripcion = new Inscripcion(null,tipDoc, cedulaE, nombresA,apellidosA, selectedDate, sex,jornada, grado, estudio,institucionAntes, personasVive, hijoEx, state2.checkedA && "S", null,"usuario",new Date(), null, null, 'A',25,arrayRepresentantes);
      let objInscripcion = new Inscripcion(null, tipDoc, cedulaE.textmask,nombresA, apellidosA, selectedDate, sex, jornada, grado, estudio,institucionAntes, personasVive, hijoEx,  state2.checkedA && "S",null,usuario,hoy, null, null, 'A',state.opciones.tasaInscripcion,arrayRepresentantes,null,"N",state.opciones.periodoinscripcion,"","");
      //cargar el carrito de inscripciones
      /*Validar que ya existas un alumno con esa cedula en el carrito*/
      state.cartIns.forEach((item) =>{
        if(item.numDoc === objInscripcion.numDoc){
          validate = true;
        }
      })

      if(!validate){
        dispatch({ type: "ADD_CART_INS", payload: objInscripcion });
        dispatch({ type: "ADD_PAGADOR", payload: objPago });
        return alert("¡Se ha añadido a su carrito nuevo items!")
      }else{
          return alert("¡Ya existe registrado un aspirante con ese número de identificación!")
      }
     

   } catch (error) {
       alert("Error al agregar inscripción");
   }
  }

// seccion form alumno
  const validaControles = () =>{
    try {
      if(tipDoc === "P"){
        return(
          <Box>
            <Typography className={classes.title}>Número de pasaporte:(*)</Typography>
            <TextField
              inputRef={refCedulaE}
              label=""
              value={cedulaE.textmask}
              className={classes.textBox}
              onChange={changeCedE}
              name="textmask"
              id="identificacionE"
              variant="outlined"
              inputProps={{maxLength :13}}
              error={errorCedulaE}
              onBlur={()=>{
                  if(tipDoc === "PA"){
                    if(cedulaE.textmask.trim() === ""){
                      //alert("¡Número de pasaporte no puede estar vacío!")
                      //refCedulaE.current.focus();
                      validarCedulaE(true);
                    }else if(cedulaE.textmask.trim().length > 13){
                      //alert("Pasaporte solo puede tener 13 dígitos!");
                      validarCedulaE(true);
                      //refCedulaE.current.focus();
                    }else{
                      validarCedulaE(false);
                    }
                }  
            }}/> 
          </Box>
        )      
      } else {
        return(
          <Box>
            <Typography className={classes.title}>Número de identificación:(*)</Typography>
            <TextField
              inputRef={refCedulaE  }
              label=""
              className={classes.textBox}
              value={cedulaE.textmask}
              onChange={changeCedE}
              name="textmask"
              id="identificacionE"
              variant="outlined"
              //InputProps={{inputComponent: TextMaskCedula,}}
              onBlur={()=>{
                  if(tipDoc === "C"){
                    if(cedulaE.textmask.trim().length > 10 || cedulaE.textmask.trim().length < 10){
                      //refCedulaE.current.focus();
                      validarCedulaE(true);
                      //return alert ("¡Número de cédula tiene que contener 10 dígitos.");
                    }else{
                      let verifica = verificarCedula(cedulaE.textmask.trim());
                      if(!verifica){
                          validarCedulaE(true);
                          //refCedulaE.current.focus();
                          //return alert("¡Número de cédula inválido!");
                      }else{
                          validarCedulaE(false);
                      } 
                    }
                  }   
                }}
              error={errorCedulaE}
            />
          </Box>
        )
      }           
    }catch (error) {
       console.log("Error en la validacion de identificaciones.") 
    }
  }

//Papá
  const validaControlesPadre = () =>{
    try {
      if(tipDocP === "R"){
        return (
          <Box>
              <Typography className={classes.title}>Número de RUC:(*)</Typography>
              <TextField
              inputRef={refCedulaP}
              label=""
              value={cedulap.textmask}
              onChange={changeCedP}
              name="textmask"
              id="ruc"
              variant="outlined"
              /*InputProps={{
                inputComponent: TextMaskRuc
              }}*/
              onBlur={()=>{
                if(tipDocP === "R"){
                  if(cedulap.textmask.trim() === ""){
                    //alert("¡Número de RUC no puede estar vacío!")
                    //refCedulaP.current.focus();
                    validarCedulaP(true);
                  }else{
                    validarCedulaP(false);
                  }
                }     
              }}
              error={errorCedulaP}
            /> 
          </Box>
        )
      }else if(tipDocP === "P"){
              return(
                <Box>
                  <Typography className={classes.title}>Número de pasaporte:(*)</Typography>
                  <TextField
                    inputRef={refCedulaP}
                    label=""
                    value={cedulap.textmask}
                    onChange={changeCedP}
                    name="textmask"
                    id="identificacionP"
                    variant="outlined"
                    inputProps={{maxLength :13}}
                    error={errorCedulaP}
                    onBlur={()=>{
                        if(tipDocP === "P"){
                          if(cedulap.textmask.trim() === ""){
                            //alert("¡Número de pasaporte no puede estar vacío!")
                            //refCedulaP.current.focus();
                            validarCedulaP(true);
                          }else if(cedulap.textmask.trim().length > 13){
                              //alert("Pasaporte solo puede tener 13 dígitos!");
                              validarCedulaP(true);
                              //refCedulaP.current.focus();
                          }else{
                            validarCedulaP(false);
                          }
                        }  
                  }}/>
                </Box> 
              )
      } else {
          return(
            <Box>
              <Typography className={classes.title}>Número de identificación:(*)</Typography>
              <TextField
                inputRef={refCedulaP}
                label=""
                value={cedulap.textmask}
                onChange={changeCedP}
                name="textmask"
                id="identificacionP"
                variant="outlined"
                /*InputProps={{
                  inputComponent: TextMaskCedula,
                }}*/
                onBlur={()=>{
                    if(tipDocP === "C"){
                      if(cedulap.textmask.trim().length > 10 || cedulap.textmask.trim().length < 10){
                        //refCedulaP.current.focus();
                        validarCedulaPa(true);
                        //return alert ("¡Número de cédula tiene que contener 10 dígitos.");
                      }else{
                        let verifica = verificarCedula(cedulap.textmask.trim());
                        if(!verifica){
                            validarCedulaP(true);
                            //refCedulaP.current.focus();
                            //return alert("¡Número de cédula inválido!");
                            
                        }else{
                            validarCedulaP(false);
                        }
                          
                        }
                  }   
              }}
              error={errorCedulaP}
            />
            </Box>
          )
      }  
    }catch (error) {
      console.log("Ocurrio un error en identificacion padre")
    }
  }
      
//Mama
  const validaControlesMadre = () =>{
    try {
      if(tipDocM === "R"){
        return (
          <Box>
              <Typography className={classes.title}>Número de RUC:(*)</Typography>
              <TextField
                inputRef={refCedulaM}
                label=""
                value={cedulaM.textmask}
                style={{width:"30%"}}
                onChange={changeCedM}
                name="textmask"
                id="ruc"
                variant="outlined"
                /*InputProps={{
                  inputComponent: TextMaskRuc
                }}*/
                onBlur={()=>{
                  if(tipDocM === "R"){
                    if(cedulap.textmask.trim() === ""){
                      //alert("¡Número de RUC no puede estar vacío!")
                      //refCedulaM.current.focus();
                      validarCedulaM(true);
                    }else{
                      validarCedulaM(false);
                    }
                  }     
                }}
                error={errorCedulaM}
              /> 
          </Box>
        )
      }else if(tipDocM === "P"){
        return(
          <Box>
            <Typography className={classes.title}>Número de pasaporte:(*)</Typography>
            <TextField
            inputRef={refCedulaM}
            label=""
            style={{width:"30%"}}
            value={cedulaM.textmask}
            onChange={changeCedM}
            name="textmask"
            id="identificacionM"
            variant="outlined"
            inputProps={{maxLength :13}}
            error={errorCedulaM}
            onBlur={()=>{
                if(tipDocM === "P"){
                  if(cedulaM.textmask.trim() === ""){
                    //alert("¡Número de pasaporte no puede estar vacío!")
                    //refCedulaM.current.focus();
                    validarCedulaM(true);
                  }else if(cedulaM.textmask.trim().length > 13){
                      //alert("Pasaporte solo puede tener 13 dígitos!");
                      validarCedulaM(true);
                      //refCedulaM.current.focus();
                  }else{
                    validarCedulaM(false);
                  }
              }  
          }}
          />
          </Box> 
        )
      }else{
        return(
          <Box>
            <Typography className={classes.title}>Número de identificación:(*)</Typography>
            <TextField
            inputRef={refCedulaM}
            label=""
            style={{width:"30%"}}
            value={cedulaM.textmask}
            onChange={changeCedM}
            name="textmask"
            id="identificacionM"
            variant="outlined"
            /*InputProps={{
              inputComponent: TextMaskCedula,
            }}*/
            onBlur={()=>{
                if(tipDocM === "C"){
                  if(cedulaM.textmask.trim().length > 10 || cedulaM.textmask.trim().length < 10){
                    //refCedulaM.current.focus();
                    validarCedulaM(true);
                    //return alert ("¡Número de cédula tiene que contener 10 dígitos.");
                  }else{
                    let verifica = verificarCedula(cedulaM.textmask.trim());
                    if(!verifica){
                        validarCedulaM(true);
                        //refCedulaM.current.focus();
                        //return alert("¡Número de cédula inválido!");
                    }else{
                        validarCedulaM(false);
                    }
                  }
              }   
          }}
          error={errorCedulaM}
          />
          </Box>
        )
      }  
    } catch (error) {
      console.log("Error en la validacion de identificacion madre")   
    }
  }

//Pagador
  const validaControlesPago = () =>{
    try {
    if(tipoDocPag === "RUC"){
      return (
        <Box >
            <Typography className={classes.title}>Número de RUC:(*)</Typography>
        <TextField
        inputRef={refCedulaPa}
        label=""
        value={cedulaPa.textmask}
        onChange={changeCedPa}
        name="textmask"
        id="ruc"
        variant="outlined"
        style={{width:"25%"}}
        /*InputProps={{
          inputComponent: TextMaskRuc
        }}*/
        onBlur={()=>{
          if(tipoDocPag === "RUC"){
            if(cedulaPa.textmask.trim() === ""){
              //alert("¡Número de RUC no puede estar vacío!")
              //refCedulaPa.current.focus();
              validarCedulaPa(true);
            }else{
              validarCedulaPa(false);
            }
        }     
    }}
        error={errorCedulaPa}
      /> 
    </Box>
    )

    }else if(tipoDocPag === "PPN"){
            return(
              <Box>
                <Typography className={classes.title}>Número de pasaporte:(*)</Typography>
                <TextField
                inputRef={refCedulaPa}
                label=""
                value={cedulaPa.textmask}
                onChange={changeCedPa}
                name="textmask"
                id="identificacionPag"
                style={{width:"25%"}}
                variant="outlined"
                inputProps={{maxLength :13}}
                error={errorCedulaPa}
                onBlur={()=>{
                    if(tipoDocPag === "PPN"){
                      if(cedulaPa.textmask.trim() === ""){
                        //alert("¡Número de pasaporte no puede estar vacío!")
                        //refCedulaPa.current.focus();
                        validarCedulaPa(true);
                      }else if(cedulaPa.textmask.trim().length > 13){
                          //alert("Pasaporte solo puede tener 13 dígitos!");
                          validarCedulaPa(true);
                          //refCedulaPa.current.focus();
                      }else{
                        validarCedulaPa(false);
                      }
                  }  
              }}
                
              />
              </Box> 
            )
            
        }else{
            return(
              <Box>
                <Typography className={classes.title}>Número de identificación:(*)</Typography>
                <TextField
                inputRef={refCedulaPa}
                label=""
                value={cedulaPa.textmask}
                onChange={changeCedPa}
                style={{width:"25%"}}
                name="textmask"
                id="identificacionPag"
                variant="outlined"
                /*InputProps={{
                  inputComponent: TextMaskCedula,
                }}*/
                onBlur={()=>{
                    if(tipoDocPag === "CI"){
                      if(cedulaPa.textmask.trim().length > 10 || cedulaPa.textmask.trim().length < 10){
                        //refCedulaPa.current.focus();
                        validarCedulaPa(true);
                        //return alert ("¡Número de cédula tiene que contener 10 dígitos.");
                      }else{
                        let verifica = verificarCedula(cedulaPa.textmask.trim());
                        if(!verifica){
                            validarCedulaPa(true);
                            //refCedulaPa.current.focus();
                            //return alert("¡Número de cédula inválido!");
                           
                        }else{
                            validarCedulaPa(false);
                        }
                         
                        }
                  }   
              }}
              error={errorCedulaPa}
              />
              </Box>
            )
        }
             
          
                
    } catch (error) {
        
    }
  }

  return (
    <Box className={classes.root}>
    <Paper>
      <Grid style={{marginTop:60}}>
        <Tabs value={paso} onChange={changeSteps} aria-label="simple tabs example">
          <Tab label="1. Información del estudiante" {...a11yProps(0)} />
          <Tab label="2. Información Adicional" {...a11yProps(1)} />
          <Tab label="3. Datos del pagador" {...a11yProps(2)} />
        </Tabs>  
     
      {/* PASO 1 */}
      <TabPanel value={paso} index={0}>
            <FormControl component="fieldset" className={classes.encabezados}>
                <FormLabel component="legend">Ingrese los datos del alumno, los campos con (*) son obligatorios.</FormLabel>
                
                <Box className={classes.section}>
                    <Typography component={'div'} className={classes.title}>Documento de identidad del aspirante(*)</Typography>
                    <RadioGroup aria-label="gender" name="gender1" value={tipDoc} onChange={handleTipDoc} defaultValue="C" style={{flexDirection:"row"}}>
                        <FormControlLabel value="C" control={<Radio color="primary"/>} label="Cédula" />
                        <FormControlLabel value="P" control={<Radio color="primary" />} label="Pasaporte" />
                    </RadioGroup>
                </Box>
                <Box className={classes.section}>  
                  {/* <InputLabel htmlFor="component-outlined">Número de identificación</InputLabel> */}
                  {
                        validaControles()         
                  }       
                </Box>
                <Box className={classes.section}>
                <Typography component={'div'} className={classes.title}>Fecha de Nacimiento:(*)</Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around"></Grid>
                <KeyboardDatePicker
                    margin="normal"
                    format="yyyy/MM/dd"
                    
                    id="date-picker-inline"
                    //label="Date picker inline"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'Cambiar fecha',
                    }}
                    />
                    </MuiPickersUtilsProvider>
                </Box>

                <Box className={classes.section}>
                    <Typography component={'div'} className={classes.title}>Nombres y Apellidos:(*)</Typography>
                    <Box>
                             <TextField required className={classes.textBox} id="nombre1" label="Nombres" variant="outlined" value={nombresA} onChange={onChangeNomE}/>
                             <TextField className={classes.textBox} id="nombre2" label="Apellidos" variant="outlined" value={apellidosA} onChange={onChangeApelE}/>
                    </Box>
                </Box>

                <Box className={classes.section}>
                    <Typography component={'div'} className={classes.title}>Sexo:(*)</Typography>
                    <Box>
                    <TextField
                    id="sexo"
                    select
                    className={classes.combo}
                    label=""
                    value={sex}
                    onChange={sexhandleChange}
                    helperText="Por favor seleccione su sexo"
                    >
                    {sexo.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                        {option.label}
                        </MenuItem>
                    ))}
                    </TextField>
                    </Box>
                </Box>
        
                <Box className={classes.section}>
                    <Typography component={'div'} className={classes.title}>Jornada:(*)</Typography>
                <Box>
                    <TextField
                    id="jornada"
                    select
                    className={classes.combo}
                    label=""
                    value={jornada}
                    onChange={jornadahandleChange}
                    helperText="Por favor seleccione su jornada"
                    >
                    {listJornada.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                        {option.label}
                        </MenuItem>
                    ))}
                    </TextField>
                    </Box>
                </Box>

                <Box className={classes.section}>
                <Typography component={'div'} className={classes.title}>Grado que aplica:(*)</Typography>
                <Box>
                    <TextField
                    id="gradoAplica"
                    select
                    className={classes.combo}
                    label=""
                    value={grado}
                    onChange={gradoChange}
                    helperText="Por favor seleccione un grado"
                    >
                {state.grados ? state.grados.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                    {option.label}
                    </MenuItem>
                )) : <MenuItem key={1} value={"Default"}>
                      Default
                    </MenuItem>}
                    </TextField>
            </Box>
        </Box>

                <Box className={classes.section}>
                        <Typography component={'div'}  className={classes.title}> Estudió anteriormente en la ANAI:(*)</Typography>
                        <Box>
                            <TextField
                            id="estudioAnt"
                            select
                            className={classes.combo}
                            label=""
                            value={estudio}
                            onChange={estudiosAntChange}
                            helperText=""
                            >
                        {listSiNo.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                        </TextField>
                        </Box>
                    </Box>
            
                <Box className={classes.section}>
                    <Typography component={'div'} className={classes.title}>Institución educativa de la que procede:(*)</Typography>
                    <Box>
                        <TextField  className={classes.textBox} 
                                    id="institucionAntes" label="" 
                                    variant="outlined" 
                                    value={institucionAntes}
                                    onChange={institucionChange}
                        />          
                    </Box>
                </Box>

                <div className={classes.section}>
                    <Typography component={'div'} className={classes.title}>Personas con las que vive el estudiante:(*)</Typography>
                    <div>
                    <TextField  style={{marginRight:15, width:"70%"}} 
                                    id="personas" label="" 
                                    variant="outlined" 
                                    multiline
                                    rows={6}
                                    value={personasVive}
                                    onChange={personasChange}
                        /> 
                                           
                    </div>
                    </div>

                <div className={classes.section}>
                    <Typography component={'div'} className={classes.title}>¿El aspirante es hijo de ex-alumnos?:(*)</Typography>
                    <div>
                    <TextField
                        id="hijoExAlumno"
                        style={{marginRight:15, width:"20%"}}
                        select
                        label=""
                        value={hijoEx}
                        onChange={hijoExChange}
                        helperText=""
                        >
                    {listSiNo.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                        {option.label}
                        </MenuItem>
                    ))}
                    </TextField>          
                    </div>
                </div>
            
                <div className={classes.section}>
                    <Typography component={'div'} className={classes.title}>Consentimiento:(*)</Typography>
                    <FormControlLabel
                    control={
                        <Checkbox
                            checked={state2.checkedA}
                            onChange={checkChange}
                            name="checkedA"
                            color="primary"
                        />
                        }
                        label="El estudiante debe tener una calificación de A o B en comportamiento"
                        />
                        <Typography className={classes.note}>Condición importante para su admisión.</Typography>
                    </div>
                    <Box className={classes.botones}>
                    <Button   style={{marginLeft:20}}
                        variant="contained"
                        //className="button-login"
                        color="primary" onClick={()=>{navigate("/menuMain")}}>Menu Inicio </Button>

                    <Button  style={{marginLeft:20}}
                      variant="contained" 
                      //className="button-login" 
                      color="primary" onClick={()=>{
                        setAnchorEl(null);
                        dispatch({ type: "REMOVE_TOKEN" });
                        dispatch({ type: "RESET_CART_INS" });
                        navigate("/");
                      }}>Salir</Button>

                  <Button   style={{marginLeft:20}}
                        variant="contained"
                        //className="button-login"
                        color="primary" onClick={()=>{changeSteps('',1)}}>Siguiente </Button>
                </Box>
                
        </FormControl>
      </TabPanel>

      {/* PASO 2 */}
      <TabPanel value={paso} index={1}>
        <FormControl component="fieldset" className={classes.encabezados}>
            <FormLabel component="legend" >Ingresar los siguientes datos, los campos con (*) son obligatorios.</FormLabel>
            <Box className={classes.section}>
              <Box style={{width:"100%"}}>
                <Box className={classes.section}>
                    <Typography component={'div'} className={classes.title}>Documento de identidad del padre:(*)</Typography>
                    <RadioGroup aria-label="gender" name="gender1" value={tipDocP} onChange={changeTipoDocP} defaultValue="C" style={{flexDirection:"row"}}>
                        <FormControlLabel value="C" control={<Radio color="primary"/>} label="Cédula" />
                        <FormControlLabel value="P" control={<Radio color="primary" />} label="Pasaporte" />
                        <FormControlLabel value="R" control={<Radio color="primary" />} label="RUC" />
                    </RadioGroup>
                </Box>
                </Box>
            </Box>
                
                <Box className={classes.section}>
                  <Box style={{width:"100%"}}>
                {
                                validaControlesPadre()
                }
                </Box>
                </Box>
                <Box className={classes.section}>
                  
                    <Typography component={'div'} className={classes.title}>Nombres del padre:(*)</Typography>
                    <Box>
                             <TextField style={{marginRight:15, width:"30%"}} id="nombreP" label="Nombres" variant="outlined" value={nombresP} onChange={changeNombresP}/>
                             <TextField style={{marginRight:15, width:"30%"}} id="apellidoP" label="Apellidos" variant="outlined" value={apellidosP} onChange={changeApellidosP}/>
                    </Box>
                </Box>
                

                
                <Box className={classes.section}>
                  <Box style={{width:"100%"}}>
                    <Box className={classes.section}>
                        <Typography component={'div'} className={classes.title}>Documento de identidad de la madre:(*)</Typography>
                        <RadioGroup aria-label="gender" name="gender1" value={tipDocM} defaultValue="C" onChange={changeTipoDocM} style={{flexDirection:"row"}}>
                            <FormControlLabel value="C" control={<Radio color="primary"/>} label="Cédula" />
                            <FormControlLabel value="P" control={<Radio color="primary" />} label="Pasaporte" />
                            <FormControlLabel value="R" control={<Radio color="primary" />} label="RUC" />
                        </RadioGroup>
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.section}>
                <Box style={{width:"100%"}}>
                {
                                validaControlesMadre()
                              }
                </Box>
                </Box>

                <Box className={classes.section}>
                 <Box style={{width:"100%"}}>
                    <Typography component={'div'} className={classes.title}>Nombres de la madre:(*)</Typography>
                    <Box>
                             <TextField style={{marginRight:15, width:"30%"}} id="nombreM" label="Nombres" variant="outlined" value={nombresM} onChange={changeNombresM}/>

                             <TextField style={{marginRight:15, width:"30%"}} id="apellidoM" label="Apellidos" variant="outlined"value={apellidosM} onChange={changeApellidosM} />
                    </Box>
                </Box>
                </Box>

                <Box className={classes.section}>
                    <Typography component={'div'}  className={classes.title}>Correo electrónico del padre:(*)</Typography>
                    <Box>
                      <TextField style={{marginRight:15, width:"30%"}} id="correoP"  value={mail} label="" variant="outlined" onChange={changeMail}/>
                    </Box>
                </Box>
                <Box className={classes.section}>
                    <Typography component={'div'} className={classes.title}>Correo electrónico de la madre:(*)</Typography>
                    <Box>
                             <TextField style={{marginRight:15, width:"30%"}} id="correoM"  value={mailM} label="" variant="outlined" onChange={changeMail2}/>
                    </Box>
                </Box>

                <Box className={classes.section}>
                  <Box style={{width:"100%"}}>
                    <Typography component={'div'} className={classes.title}>Número celular:(*)</Typography>
                    <Box>
                             <TextField  type="number"  
                             
                             /*onInput={(e)=>{ 
                                e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                            }} */
                             inputProps={{maxLength: 10 }} style={{marginRight:15, width:"30%"}} id="celular"  value={celular} label="" variant="outlined" onChange={changeCelular}/>
                    </Box>
                </Box>
                </Box>

                <Box className={classes.section}>
                  <Box style={{width:"100%"}}>
                    <Typography component={'div'} className={classes.title}>Teléfono convencional:(*)</Typography>
                    <Box>
                             <TextField type="number"  
                             /*onInput={(e)=>{ 
                                  e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                              }} */
                              style={{marginRight:15, width:"30%"}} id="convencional"  value={convencional} label="" variant="outlined" onChange={changeConvencional}/>
                    </Box>
                </Box>
                </Box>

                
                <Box className={classes.botones}>
                  <Button  style={{marginRight:20}}
                      variant="contained" 
                      //className="button-login" 
                      color="primary" onClick={()=>{changeSteps('',0)}}>Anterior</Button>

                  <Button   style={{marginLeft:20}}
                        variant="contained"
                        //className="button-login"
                        color="primary" onClick={()=>{changeSteps('',2)}}>Siguiente </Button>
                    </Box>
        </FormControl>
      </TabPanel>
      {/* PASO 3 */}
      <TabPanel value={paso} index={2}>
      <FormControl component="fieldset" className={classes.encabezados}>

               <FormLabel >
                        Ingrese la información de la persona que realizará el pago para la inscripción.
               </FormLabel>
                <Box className={classes.section}>
                <Box >
                <Box className={classes.section}>
                    <Typography component={'div'} className={classes.title}>Documento de identidad:(*)</Typography>
                    <RadioGroup aria-label="gender" name="gender1" value={tipoDocPag} defaultValue="CI" onChange={handleTipDocPag} style={{flexDirection:"row"}}>
                        <FormControlLabel value="CI" control={<Radio color="primary"/>} label="Cédula" />
                        <FormControlLabel value="PPN" control={<Radio color="primary" />} label="Pasaporte" />
                        <FormControlLabel value="RUC" control={<Radio color="primary" />} label="RUC" />
                    </RadioGroup>
                </Box>
                </Box>
            </Box>
                <Box className={classes.section}>
                    
                              {
                                validaControlesPago()
                              }
                  </Box>
                <Box className={classes.section}>
                  <Box styles={{width:"50%"}}>
                    <Typography className={classes.title}>Nombres:(*)</Typography>
                    <Box>
                             <TextField style={{marginRight:15, width:"20%"}} id="nombrePa" label="Nombres" variant="outlined" value={nombresPa} onChange={onChangeNomPa}/>

                             <TextField style={{marginRight:15, width:"20%"}} id="apellidoPa" label="Apellidos" variant="outlined"value={apellidosPa} onChange={onChangeApelPa} />
                    </Box>
                </Box>
                </Box>

                <Box className={classes.section}>
                    <Typography className={classes.title}>Correo electrónico:(*)</Typography>
                    <Box>
                             <TextField style={{marginRight:15, width:"30%"}} id="correoPa"  value={mailPa} label="Correo electrónico" variant="outlined" onChange={changeMailPa}/>
                    </Box>
                </Box>
                <Box className={classes.section}>
                    <Typography className={classes.title}>Dirección:(*)</Typography>
                    <Box>
                      <TextField style={{marginRight:15, width:"30%"}} id="direccionPa"  value={direccionPa} label="Direccion" variant="outlined" onChange={changeDireccionPa}/>
                    </Box>
                </Box>
                {/* comentado este parrafo  */}
                <Box className={classes.section} hidden="true" >
                  <Typography style={{width:"600px"}}>
                  Una vez procesado el pago deberá cargar unas imágenes de la libreta de grado y una imagen de la inscripción de nacimiento o cédula de identidad del aspirante inscrito.
                  </Typography>     
                </Box>
                <Box className={classes.section}>
                    <Box style={{flexDirection:"row",textAlign:"center", display:"flex", backgroundColor:"#9fa8da", width:"15%"}}>
                        <Typography component={'div'} className={classes.titleTasa}>Tasa de inscripción:</Typography>
                        
                        <Typography component={'div'} style={{fontWeight:"bold"}}>${state.opciones ? state.opciones.tasaInscripcion : "0"}</Typography>
                       
                    </Box>
                    <Typography style={{width:"40%"}}>Revise la información ingresada antes de añadir al carrito, una vez añadido al carrito se redirigirá al botón de pago, para proceder con el pago ya sea con tarjeta de crédito o débito.</Typography>
                </Box>
  
                <Box className={classes.botones}>
                  <Button  style={{marginRight:20}}
                          variant="contained" 
                          //className="button-login" 
                          color="primary" onClick={()=>{changeSteps('',1)}}>Anterior</Button>

                  <Button   style={{marginLeft:20}}
                            variant="contained"
                            //className="button-login"
                            color="primary" onClick={()=>{validarData()}}>Agregar al carrito </Button>
      </Box>
        </FormControl>
            
      </TabPanel>
      </Grid>
      </Paper>
      </Box>
  );
}