export default class Inscripcion{
    constructor(
        id_inscripcion,
        tipoDoc,
        numDoc,
        nombres,
        apellidos,
        fechaNacimiento,
        sexo,
        jornada,
        gradoAplica,
        estudioAnterior,
        institucionProcede,
        personaVive,
        hijoExAlumno,
        consentimiento,
        observacion,
        usuarioCrea,
        fechaCrea,
        usuarioModifica,
        fechaModifica,
        estado,
        monto,
        representantes,
        idSobre,
        sta_apb,
        periodo,
        cod_aspirante,
        sta_envio,
        direccion
    ){
        this.id_inscripcion = id_inscripcion;
        this.tipoDoc = tipoDoc;
        this.numDoc = numDoc;
        this.nombres = nombres;
        this.apellidos = apellidos;
        this.fechaNacimiento = fechaNacimiento;
        this.sexo = sexo;
        this.jornada = jornada;
        this.gradoAplica = gradoAplica;
        this.estudioAnterior = estudioAnterior;
        this.institucionProcede = institucionProcede;
        this.personaVive = personaVive;
        this.hijoExAlumno = hijoExAlumno;
        this.consentimiento = consentimiento;
        this.observacion = observacion;
        this.usuarioCrea = usuarioCrea;
        this.fechaCrea = fechaCrea;
        this.usuarioModifica = usuarioModifica;
        this.fechaModifica = fechaModifica;
        this.estado = estado;
        this.monto = monto;
        this.representantes = representantes;
        this.idSobre = idSobre;
        this.sta_apb = sta_apb;
        this.periodo = periodo;
        this.cod_aspirante = cod_aspirante;
        this.sta_envio = sta_envio;
        this.direccion = direccion;
    }

}