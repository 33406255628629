import { useStore } from "../../../context";
import { navigate } from "@reach/router";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useState, useRef } from "react";
import { urlAuth, urlOpciones } from "../../../utils";
import { CopyRight } from "../../../components/Copyright";
import Info from "../../../components/Info/Info";
import { httpGet } from "../../../servicios";

const AuthMatNew = () => {
  const { dispatch } = useStore();
  const [user, setUser] = useState("");
  const [passw, setPassword] = useState("");
  const [notif, setNotif] = useState(false);
  let refe = useRef();

  function Login(event){
    event.preventDefault()
    const data = {
      "username":"N"+user,
      "password":passw
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data)
    };

    fetch(`${urlAuth}auth/login`,options)
      .then((res) => {     
        return res.json()
      })
      .then(async(data) => {
        let _opciones = await httpGet(urlOpciones,data.token);
        if(data.token){
          dispatch({type:"SET_OPCIONES", payload:_opciones.data});
          dispatch({ type: "SET_LOGIN", payload:"N"})
          dispatch({ type: "SET_TOKEN", payload: data });
          navigate("/matricula/list");
        }else{
          setNotif(true);
          //alert("Credenciales Incorrectas")
        }
      })
      .catch((err) => {
        setNotif(true);
        console.log("Error de promesa:",err);
        //alert("Error de promesa:",err)
      })
  }
  return (
    <div className="main-app">
      <Container component="main" maxWidth="xs">
        <div className="content-login">
          <Typography component="h1" variant="h5">
            Login de Nuevo Estudiante
          </Typography>
          <form className="form-login" noValidate onSubmit={Login}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Usuario"
              name="username"
              autoComplete="username"
              value={user}
              onChange={(e) => setUser(e.target.value)}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              value={passw}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="button-login"
            >
              INGRESAR
            </Button>
          </form>
        </div>
        <CopyRight />
        <Info 
          ref={refe} 
          visible={notif} 
          titulo={`Error en login de Matricula Nuevo Estudiante`} 
          mensaje={`Credenciales Incorrectas`} 
          cerrar={setNotif}
        />
      </Container>
    </div>
  );
};

export default AuthMatNew;
