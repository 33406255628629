import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import React from "react";
import { CopyRight } from "../components/Copyright";
import "./index.css";
import { navigate } from "@reach/router";
import { useStore } from "../context";
import Header from "../components/HeaderMat";
import Menu from "../components/MenuMat";
//-------------------------------------------------------------------------
// Marco Principal de Matricula
// Aqui se cargan el Header y el Menu
// En caso de no tener un token, se lo redirigie a raiz que seria el login
//-------------------------------------------------------------------------

//-------------------------------------------------------------------------
// Estilo Principal de la pagina Matricula
//-------------------------------------------------------------------------
const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
}));

//-------------------------------------------------------------------------
// JSX de el marco principal de Matricula
//-------------------------------------------------------------------------
export default ({children}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const { state } = useStore();
  React.useEffect(() => {
    if (!state.token) {
      //Redireccion a raiz si no hay token
      navigate("/");
    }
  }, [state]);
  
  return (
    <div className="flex">
      <Header open={open} setOpen={setOpen} />
      <Menu open={open} setOpen={setOpen} />
      <main className="content">
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className="container-template">
            {/*Los hijos son los componentes que se van a renderizar*/}
            {children}
          <CopyRight />
        </Container>
      </main>
    </div>

  );
};
