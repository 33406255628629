import { MuiThemeProvider } from "@material-ui/core/styles";
import { Router, Redirect } from "@reach/router";
import React from "react";
import ReactDOM from "react-dom";
import theme from "./assets/theme";
import "./index.css";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Template from "./template";
import { StoreProvider } from "./context";
import AuthPension from "./pages/Login/authPension";
import Rubros from "./pages/Pensiones/rubros";
import Pensiones from "./pages/Pensiones";
import Cart from "./pages/Cart";
import Return from "./pages/Return";
import Inscripciones from "./pages/Inscripciones";
import CartIns from "./pages/CartIns";
import ReturnIns from "./pages/ReturnIns";
import Registro from "./pages/Registro";
import AuthIns from "./pages/Login/authIns";
import AuthMain from "./pages/Login/authMain";
import TemplateIns from "./templateIns";
import FileUpload from "./pages/FileUpload";
import RecuperacionClave from "./pages/Login/recuperacionClaveEst";

import Matricula from "./Matricula";
import AuthMatNew from "./pages/Matriculacion/MatLogin/authMatNew";
import AuthMatOld from "./pages/Matriculacion/MatLogin/authMatOld";

import MatriculaList from "./pages/Matriculacion/MatriculaList";
import MatLogin from "./pages/Matriculacion/MatLogin";
import MatriculaSearch from "./pages/Matriculacion/MatriculaSearch";
import MatriculaCart from "./pages/Matriculacion/CartMat";
import MatriculaReturn from "./pages/Matriculacion/MatriculaReturn";
import MatriculaLink from "./pages/Matriculacion/LinkMat"
import LinkMat from "./pages/Link";
import MatMenu from "./pages/Matriculacion/MatMenu";
import RegistroNuevo from "./pages/RegistroNuevo";
import TagManager from 'react-gtm-module'
import MenuMain from "./pages/MenuMain";
import PrivateRoute from "./privateRoute";
import RecuperaMenu from "./pages/Login/RecuperaMenu";
import RecuperacionClaveEst from "./pages/Login/recuperacionClaveEst";
import RecuperacionClaveNue from "./pages/Login/recuperacionClaveNue";
import ContactoInscripcion from "./pages/Inscripciones/contacto";




// const tagManagerArgs = {
//   gtmId: 'GTM-TZSNK5R'
// }

// TagManager.initialize(tagManagerArgs)
// TagManager.dataLayer({
//   dataLayer: {
//     event: "pageview",
//     path: "/"
//   }
// })


//-------------------------------------------------------------------------
//Enrutador de la aplicacion de React
//-------------------------------------------------------------------------

const Ruteador = () => {
  return (
        <Router>
          <AuthMain path="/" />
          <AuthMain path="authMain"/>
          {/* <LoginMain path="loginMain"/> */}
           <PrivateRoute path="/">
            <MenuMain path="menuMain"/> 
            <MatMenu path="matMenu"/>
          </PrivateRoute>
          <RegistroNuevo path="registroAnai"/>
          {/* <Login path="/" /> */}
          <MatLogin path="loginMatricula"/>
          {/* <Registro path="registro"/> */}
          <RecuperacionClaveEst path="recuperarClaveEst"/>
          <RecuperacionClaveNue path="recuperarClaveNue"/>
          <RecuperaMenu path="recuperaMenu"/>
          <Template path="/pension"  >
            <Dashboard path="pagos" />
            <Pensiones path="list"  />
            <Rubros path="rubros/:codEst" />
            <Cart path="cart" />
            <Return path="return/:reference"/>
            
            <Redirect from="aboutus" to="about-us" uri/>
          </Template>

          <Matricula path="/matricula">
            <MatriculaList path="list"/>
            <MatriculaSearch path="search/:codEst" />
            <MatriculaCart path="cartMat" />
            <MatriculaReturn path="return/:reference" />
            <MatriculaLink path="linkMat" />
          </Matricula>

          <LinkMat path="/linkmat">
            
          </LinkMat>

          <AuthMatNew path="authNew" />
          <AuthMatOld path="authAnai" />
          <AuthPension path="authPension" />
          <AuthIns path="authIns"/>

          <TemplateIns path="/inscripcion">
            <Inscripciones path="list" />
            <CartIns path="cartIns"/>
            <ReturnIns path="return/:reference"/>
            {/* <FileUpload path="fileUpload"/> */}
            <ContactoInscripcion  path="contacto" />
          </TemplateIns>
        </Router>
  )
}

export default Ruteador;