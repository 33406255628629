import LogMessage from "../models/LogMessage";
import {httpPost} from "../servicios";
import {server} from "./index.js";

var moment = require('moment');

export default function createLogMessage(prod,ruta,message,idSobre,token){
    const logs = new LogMessage(0,ruta,message,moment(),idSobre)
    if(prod){
        httpPost(`${server}console/log`,logs,token);
    }else{
        httpPost(`${server}console/log`,logs,token);
        console.log("Console de control:",logs)
    }
    
      
}