export const PROD = true; // true produccion false developer: SI ESTE ES TRUE NO IMPORTA EL DEVSERVER
export const DEVSERVER = false;  // true testpagos false pc developer 
// URL DE PRUEBAS
export const devserver =  DEVSERVER ? "https://testpagos.anai.edu.ec/" : "http://192.168.87.42:8888/";
export const devurl =     DEVSERVER ? "https://testpagos.anai.edu.ec/" : "http://localhost:3000/";
export const devurlAuth = DEVSERVER ? "https://testpagos.anai.edu.ec/" : "http://192.168.87.42:8888/";



//URL de los servicios
export const server =  PROD ? "https://pagos.anai.edu.ec/" : devserver;
export const url =     PROD ? "https://pagos.anai.edu.ec/" : devurl;
export const urlAuth = PROD ? "https://pagos.anai.edu.ec/" : devurlAuth;

////placeToPay
let  urlPTP;
let  userPTP;
let  pwdPTP;

if (PROD){
	urlPTP  = "https://checkout.placetopay.ec/api/session";
	userPTP = "bac274b83978a231c38ed9809a537e6c";
	pwdPTP  = "6Sc2wbM2bGHFMXQF";
} else {
	urlPTP  = "https://test.placetopay.ec/redirection/api/session";
	userPTP = "b567d496538b1a396e4d6b84c13f8d20";
	pwdPTP  = "D90q0n060mwAM00b";
}

export const placeToPay = urlPTP;
export const user =       userPTP;
export const password =   pwdPTP;


// Only Node Server Proxy
export const urlRespuesta =    url + "pension/return/";
export const urlRespuestaIns = url + "inscripcion/return/";
export const urlRespuestaMat = url + "matricula/return/";
export const urlPago = "payment";
export const urlPagoMat = "payment";
export const urlPagoMatLink = "payment"
export const urlInscripciones = "cart/inscripciones";
export const urlProxyImg = "inicio/fileUpload";


//--------------------------------------------
export const urlMat = server + "matricula2/representante/";
export const urlMatNew = server + "matricula2/representante2/";
export const urlMatRub = server + "matricula2/rubros/";
export const saveCab = server + "pagoweb";
export const saveDet = server + "pagowebdet";
export const saveLog = server + "logpagoweb";
export const urlConsulta = server + "pagoweb/consulta/";
export const urlConsultaDet = server + "pagowebdet/consulta/";
export const urlPensionEstudiantes = server + "pension2/estudiante/representante/";
export const urlDashBoard = server+ "pension2/documento/";
export const urlReferencia = server+"pagoweb/secuencial/";
export const urlInserInsc = server + "inscripcion2";
export const urlOpciones =server + "inscripcion2/opciones/get";
export const urlGetGrados = server + "inscripcion2/opciones/grados";
export const urlGetInscripciones = urlInserInsc +"/consulta/";
export const urlInsertImage = server + "imagen";
export const urlRegistro = server+"registro/";
export const urlRegistroExisteNew = server+"registro/new/";
export const urlRegistroExisteAnai = server+"registro/anai/";


export function getString (file, key){
    try {
        var value = file[key]
        if(value !== "" && value !== undefined){
            return value
        }else{
            return "No existe mensaje"
        }
    } catch (error) {
        return "No existe mensaje"   
    }

}


export function verificarCedula  ( cedula) {  
  cedula = cedula.trim();
  var total = 0;  
  var tamanoLongitudCedula = 10;  
  //int[] coeficientes = {2, 1, 2, 1, 2, 1, 2, 1, 2};  
  
  let coeficientes = [];
  coeficientes[0] = 2;
  coeficientes[1] = 1;
  coeficientes[2] = 2;
  coeficientes[3] = 1;
  coeficientes[4] = 2;
  coeficientes[5] = 1;
  coeficientes[6] = 2;
  coeficientes[7] = 1;
  coeficientes[8] = 2;
  
  var numeroProviancias = 24;  
  var tercerdigito = 6;  
  if (cedula.match("[0-9]*") && cedula.length === tamanoLongitudCedula) { 
    var provincia = cedula.charAt(0) + "" + cedula.charAt(1);  
    var digitoTres =cedula.charAt(2) + "";

    if ((provincia > 0 && provincia <= numeroProviancias) && digitoTres < tercerdigito) {  
      var digitoVerificadorRecibido = cedula.charAt(9) + "";
      for (var i = 0; i < coeficientes.length; i++) {  
        var valor = (coeficientes[i] + "") * (cedula.charAt(i) + "");  
        total = valor >= 10 ? total + (valor - 9) : total + valor;  
      }
      var digitoVerificadorObtenido = total >= 10 ? (total % 10) !== 0 ? 10 - (total % 10) : (total % 10) : total;  
      if (digitoVerificadorObtenido == digitoVerificadorRecibido) {
         //validarCedula(false)
          return true;  
      }  
    }
   // validarCedula(true);
    //inputRef.current.focus();
    return false;  
  }
  //validarCedula(true)
  return false;  
} 
  
  
