import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import { useStore } from "../../../context";
import { navigate } from "@reach/router";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Badge from "@material-ui/core/Badge";

//-------------------------------------------------------------------------
// Parte Superior de la pagina. Carrito y Cerrar Sesion
//-------------------------------------------------------------------------

const MenuHeader = (type) => {
  const { state, dispatch } = useStore();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const navegar = (type)=>{
    try {
      if(type === "inscripciones"){
        navigate("/inscripcion/cartIns",)
      } else{
        navigate ("/pension/cart")
      }
    } catch (error) {
      
    }
  }

//-------------------------------------------------------------------------
// JSX de la cabecera, basados en si es inscripciones o pensiones
//-------------------------------------------------------------------------

  return (
    <>
      <IconButton style={{ color: "#fff" }} onClick={() => navegar(type.type)}>
        
        {type.type === "inscripciones" ?
          <Badge badgeContent={ state.cartIns.length} color="secondary">
          <ShoppingCartIcon />
        </Badge>
        :
        <Badge badgeContent={ state.cart.length} color="secondary">
        <ShoppingCartIcon />
      </Badge>
        }
      </IconButton>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        style={{ color: "#fff" }}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={openMenu}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          width: "20ch",
        }}
      >
        {
          type.type === "inscripciones" ? 
          <MenuItem
              onClick={() => {
                setAnchorEl(null);
                dispatch({ type: "REMOVE_TOKEN" });
                dispatch({ type: "RESET_CART_INS" });
                dispatch({ type: "REMOVE_PROCESS_URL" });
                dispatch({ type: "REMOVE_USER" });
                dispatch({ type: "REMOVE_PAGADOR" });
                dispatch({ type: "REMOVE_OPCIONES" });
                dispatch({ type: "REMOVE_GRADOS" });
                dispatch({ type: "REMOVE_ID_PAYMENT" });
                dispatch({ type: "REMOVE_KEYS" });
                navigate("/");
              }}
            >
              Cerrar Sesión
            </MenuItem>
          :<MenuItem
          onClick={() => {
            setAnchorEl(null);
            dispatch({ type: "REMOVE_TOKEN" });
            dispatch({ type: "RESET_CART" });
            dispatch({ type: "REMOVE_PROCESS_URL" });
            dispatch({ type: "REMOVE_USER" });
            dispatch({ type: "REMOVE_ID_PAYMENT" });
            dispatch({ type: "REMOVE_KEYS" });
            navigate("/");
          }}
        >
          Cerrar Sesión
        </MenuItem>
        }
        
      </Menu>
    </>
  );
};

export default MenuHeader;
