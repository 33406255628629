export default class PagoWebDet{
    constructor(
                    idSobre,
                    idItem,
                    valorProcc,
                    nomEst,
                    referencia,
                    dscRub,
                    fechaProceso,
                    numDoc,
                    staCob,
                    dscObs,
                    idCobro,
                    fechaCobro,
                    periodo,
                    codNiv,
                    codEst,
                    codRub,
                    fecPag,
                    gruFac,
                    tipCob,
                    clasificacion,
                    staCaj,
                    tipCnt,
                    valAbono,
                    staFac,
                    id_inscripcion
    ){
        this.idSobre = idSobre;
        this.idItem = idItem;
        this.valorProcc = valorProcc;
        this.nomEst = nomEst;
        this.referencia = referencia;
        this.dscRub = dscRub;
        this.fechaProceso = fechaProceso;
        this.numDoc = numDoc;
        this.staCob = staCob;
        this.dscObs = dscObs;
        this.idCobro = idCobro;
        this.fechaCobro = fechaCobro;
        this.periodo = periodo;
        this.codNiv = codNiv;  
        this.codEst = codEst;
        this.codRub = codRub; 
        this.fecPag = fecPag;
        this.gruFac = gruFac;
        this.tipCob = tipCob;
        this.clasificacion = clasificacion;
        this.staCaj = staCaj;
        this.tipCnt = tipCnt;
        this.valAbono = valAbono;
        this.staFac = staFac;
        this.id_inscripcion = id_inscripcion;
    }
}