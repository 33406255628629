import React, { useEffect } from "react";
import { Box, Button, MenuItem, TextField, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
      //flexGrow: 1,
      backgroundColor:theme.palette.background.paper,
      width:"100%",
     
    },
    title:{
        fontWeight:"bold"
    },
    section:{
        marginBottom:10,
        marginTop:20
    },
    combo:{
        width:"80%"
    }
}))

export default function ContactoInscripcion (){
    const classes = useStyles();
    
    useEffect(() => {
        
    }, [])
    
    

    //-------------------------------------------------------------------------
    // JSX que renderiza la pagina
    //-------------------------------------------------------------------------

    return(
        <Box>
            <Typography variant="h6">Mensaje Importante</Typography>
            <br/>
            <Typography>
                Su pago fue realizado exitosamente. El departamento de consejeros estudiantil DECE se pondrá en contacto con usted para agendar una cita.
            </Typography>
            
            
       </Box>
    )
}