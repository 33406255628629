import { useStore } from "../../context";
import { navigate } from "@reach/router";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useState, useRef } from "react";
import { CopyRight } from "../../components/Copyright";
import {urlAuth, urlGetGrados, urlOpciones, urlRegistroExisteAnai} from "../../utils";
import { httpGet } from "../../servicios";
import Info from "../../components/Info/Info";

const AuthIns = () => {
  const { dispatch } = useStore();
  const [user, setUser] = useState("");
  const [passw, setPassword] = useState("");
  const [notif, setNotif] = useState(false);
  const [mensaje, setMensaje] = useState("");
  let refe = useRef();
  
  async function Login(event){
    event.preventDefault()
    const data = {
      "username":"N"+user,
      "password":passw
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data)
    };

    let existenciaUsuarioAnai = await httpGet(urlRegistroExisteAnai+user)
    if(existenciaUsuarioAnai.data.existe){
      setNotif(true);
      setMensaje("Existe como usuario ANAI");
    }else{
      fetch(`${urlAuth}auth/login`,options)
      .then((res) => {    
        return res.json()
      })
      .then(async(data) => {
        let _opciones = await httpGet(urlOpciones,data.token);
        let _grados   = await httpGet(urlGetGrados,data.token);
        if(data.token){
          dispatch({type:"SET_OPCIONES", payload:_opciones.data})
          dispatch({type:"SET_GRADOS",   payload:_grados.data})
          dispatch({type:"SET_TOKEN",    payload: data });
          navigate("/inscripcion/list");
        }else{
          setNotif(true);
          setMensaje("Credenciales Incorrectas");
        }
      })
      .catch((err) => {
        setNotif(true);
        setMensaje("Credenciales Incorrectas");
        console.log("Error de promesa:",err);
      })
    }
    
  }

  return (
    <div className="main-app">
      <Container component="main" maxWidth="xs">
        <div className="content-login">
          <Typography component="h1" variant="h5">
          Ingreso a Inscripciones
          </Typography>
          <form className="form-login" noValidate onSubmit={Login}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Usuario"
              name="username"
              autoComplete="username"
              value={user}
              onChange={(e) => setUser(e.target.value)}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              value={passw}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="button-login"
            >
              INGRESAR
            </Button>
          </form>
          <Typography>
            <h4>Este proceso es sólo para ASPIRANTE A CADETE, es decir ALUMNO NUEVO QUE NO HAYA
ESTUDIADO EN LA ANAI</h4>
          </Typography>
        </div>
        <CopyRight />
        <Info 
          ref={refe} 
          visible={notif} 
          titulo={`Error en Ingreso a Inscripciones`} 
          mensaje={mensaje} 
          cerrar={setNotif}
        />
      </Container>
    </div>
  
  );
};

export default AuthIns;
