import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import React from "react";
import { navigate } from "@reach/router";
import Tooltip from '@material-ui/core/Tooltip';

//-------------------------------------------------------------------------
// Parte de la izquierda de la cabecera de matricula
//-------------------------------------------------------------------------

const menuItems = (
  <div>
    <Tooltip title="Lista">
      <ListItem button onClick={() => navigate("/matricula/list")}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Lista" />
      </ListItem>
    </Tooltip>
    
  </div>
);

export default menuItems;
