import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SchoolIcon from "@material-ui/icons/School";
import React from "react";
import { navigate } from "@reach/router";
import Tooltip from '@material-ui/core/Tooltip';

//-------------------------------------------------------------------------
// Menu Especifico para Pensiones
//-------------------------------------------------------------------------

const menuItems = (
  <div>
    <Tooltip title="Dashboard">
      <ListItem button onClick={() => navigate("/pension/pagos")}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
    </Tooltip>
    
    <Tooltip title="Pensiones">
      <ListItem button onClick={() => navigate("/pension/list")}>
        <ListItemIcon>
          <SchoolIcon />
        </ListItemIcon>
        <ListItemText primary="Pensiones" />
      </ListItem>
    </Tooltip>
    
  </div>
);

export default menuItems;
