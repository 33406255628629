import React, { createContext, useContext, useReducer } from "react";
import reducer, { defaultState } from "./reducer";

const StoreContext = createContext(null);
 
//-------------------------------------------------------------------------
// Contexto para reducer
//-------------------------------------------------------------------------

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);
