import React, { useEffect, useState, useCallback  } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { navigate } from "@reach/router";
import { useStore } from "../../context";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Paper from "@material-ui/core/Paper";
import { httpPostProxy} from "../../servicios";
import {placeToPay, user, password, urlPago} from "../../utils";
import RedirectionAuth from "../../utils/autentication";
import {PROD} from "../../utils/index";
import sendLog from "../../utils/logs";

export default function Return (props) {
  const { state, dispatch } = useStore();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});


  const resetCart = useCallback(async () => {
    dispatch({ type: "RESET_CART" });
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    let _idPayment = sessionStorage.getItem('idPayment');

    if(_idPayment == null){
      alert("No tiene una sesión de pago valida");
      return;
    }

    var data = {};
    var authGenerator = new RedirectionAuth(user, password);
    var auth = authGenerator.asObject();
    data = {
      "auth": {
      "login": auth.login,
      "seed": auth.seed,
      "nonce": auth.nonce,
      "tranKey": auth.tranKey
      }
    }

    //-------------------------------------------------------------------------
    // Consulta a place to Pay
    //-------------------------------------------------------------------------
    httpPostProxy(urlPago,data, placeToPay+"/"+_idPayment).then((res) => {
      //Setea res para presentar mensaje en pagina
      setData(res);
      //Envio de log de web service
      sendLog(PROD,"Return de pensiones",res.data,_idPayment,state.token);

      //-------------------------------------------------------------------------
      // Notificacion de estado de la transaccion a usuario y redireccion
      //-------------------------------------------------------------------------
      let respuesta = JSON.parse(res.data);
      if(JSON.parse(res.data).status.status === "APPROVED"){   
        alert(`Transacción Nº ${JSON.parse(res.data).request.payment.reference}: ${JSON.parse(res.data).status.message.replace("petición","transacción")}`);
        resetCart();
        navigate("/pension/pagos");
      }else if(JSON.parse(res.data).status.status === "REJECTED"){ 
        if(respuesta.payment !== null){
          alert(`Transacción Nº ${JSON.parse(res.data).request.payment.reference}: ${respuesta.payment[0].status.message.replace("petición","transacción")}`);
        }else{
          alert(`Transacción Nº ${JSON.parse(res.data).request.payment.reference}: ${JSON.parse(res.data).status.message.replace("petición","transacción")}`);
        }
        navigate("/pension/cart")
      }else if(JSON.parse(res.data).status.status === "PENDING"){ 
        alert(`Transacción Nº ${JSON.parse(res.data).request.payment.reference}: ${JSON.parse(res.data).status.message.replace("petición","transacción")}`);
        navigate("/pension/cart")
      }
    })
      .catch((error) => sendLog(PROD,"Return de pensiones",error,_idPayment,state.token))
      .finally(() => setLoading(false));
  }, [resetCart]);

  //-------------------------------------------------------------------------
  // JSX del estado de la transaccion
  //-------------------------------------------------------------------------
  const renderMessage = ()=>{ 
    //resetCart();
    if(data.cod !== 0){
      return (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {data.mensaje}
            <strong>
              <a href={`/pension/return/${props.reference}`}>Reintentar.</a>
            </strong>
          </Alert>
        )
       
    }else{
      if(JSON.parse(data.data).status.status === 'APPROVED'){
        //dispatch({ type: "RESET_CART" });
        return (
          <div style={{alignItems:"center"}}>
              <Alert severity="success">
              <AlertTitle>
                {JSON.parse(data.data).status.message}
              </AlertTitle>
              <strong>
                <a href={`/pension/list`}>Inicio.</a>
              </strong>
            </Alert>
          </div>
         )
      }else{
        return (
          <div style={{alignItems:"center"}}>
              <Alert severity="warning">
              <AlertTitle>
                {JSON.parse(data.data).status.message}
              </AlertTitle>
              <strong>
                <a href={`/pension/list`}>Inicio.</a>
              </strong>
            </Alert>
          </div>
         )
      }

      
    }
  

  }
  //-------------------------------------------------------------------------
  // JSX de la pagina
  //-------------------------------------------------------------------------
  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }

  return(
    <Paper className="container-table">{
      renderMessage()
    }      
    </Paper>
    )
}