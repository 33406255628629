import React, { forwardRef }from 'react';
import filePdf from '../../assets/facturaElectronica.pdf';
 

export default () => {
    const handleClickPdf = () => {
        window.open( {filePdf},"_blank", 'noopener,noreferrer')
    };

    return(
        <div>
            <a href={filePdf} target="_blank" rel="noopener noreferrer" style={{color:'red'}} >
                    Conozca su Factura Electronica
            </a>
        </div>
    );
}

 