export default class UserAnai{
    constructor(
        username,
        password,
        nom_est,
        email
    ){
        this.username = username;
        this.password = password;
        this.nom_est = nom_est;
        this.email = email;
    }
}