//import {fetch as call} from 'cross-fetch';

const API = 'https://geoip-db.com/json';
const DEFAULT_QUERY = 'redux';

export function getIp(){
    return new Promise((resolve, reject) => {
        try {
            fetch(API + "/" + DEFAULT_QUERY)
            .then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error('Something went wrong ...');
                }
            })
            .then(data => {
                let resp = {cod:0, data:data};
                resolve(resp);
            })
            .catch(error => {
                                let resp = {cod:1, mensaje:"Error en consulta."};
                                reject(resp)
                            })
        }catch(error){
            let resp = {cod: 1, mensaje: "Error al llamar al servicio."}
            reject(resp)
        }
           
    })
}

export function httpPostProxyFiles(route, data, url, token){
    return new Promise((resolve, reject) => {
        try {
    
        let headers = {
            "content-type": "application/json",
        }
         if(url){
             headers["url"] = url;
             headers["authorization"] = token;
          }
            fetch(route, {
                method: "POST",
                //mode:"no-cors",
                headers: headers,
                body: JSON.stringify(data),
            
                }).then(response => response.json())
                .then(response => {
                        resolve(response);
                }).catch(err => {
                    let resp = {cod:1, mensaje:"Error en consulta."};
                    reject(resp);
                });
        } catch (error) {
            let resp = {cod: 1, mensaje: "Error al llamar al servicio."}
            reject(resp)
        }
    })
}
export function httSaveImg(route, data, token){
    return new Promise((resolve, reject) => {
        try {
           
            fetch(route, {
                    "method": "POST",
                    "headers": {
                        'Access-Control-Allow-Origin':'*',
                        'Access-Control-Allow-Headers':'Origin, Accept, Authorization',
                        //"cookie": "JSESSIONID=F99153FE2425CF8F53273F15E2EB2C97",
                        //"Content-Type": "multipart/form-data",
                        "Authorization": token,
                        //"content-type": "multipart/form-data; boundary=---011000010111000001101001"
                        
                        //"content-type" : false
                    },
                    "body": data
                    }).then(response => response.json())
                    .then(response =>{
                        let resp = {cod:0, data:response};
                        resolve(resp);
                    })
                    .catch(err => {
                        console.error(err);
                        let resp = {cod:1, mensaje:"Error en consulta."};
                        reject(resp);
                    });
        } catch (error) {
           
            let resp = {cod: 1, mensaje: "Error al llamar al servicio."}
            reject(resp)
        }
    })
}
export function httpPost(route, data, token){
    return new Promise((resolve, reject) => {
        try {
    let headers = 
        {
            
        }
    
    if(token){
        headers["Authorization"] = token;
        headers["content-type"] = "application/json";
        headers["Access-Control-Allow-Origin"] = "*";
        headers["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept, Authorization"
    }
 else{
     
        headers = {
            "cookie": "__cfduid=d617af3774d73625e18493a9d342720fc1598981533",
            "content-type": "application/json",
        }
         
    }

            fetch(route, {
                method: "POST",
                //mode:"no-cors",
                headers: headers,
                body: JSON.stringify(data),
            
                }).then(response => response.json())
                .then(response => {
                        let resp = {cod:0, data:response};
                        resolve(resp);
                }).catch(err => {
                    console.error(err.toString());
                    let resp = {cod:1, mensaje:"Error en consulta."};
                    reject(resp);
                });
        } catch (error) {
            let resp = {cod: 1, mensaje: "Error al llamar al servicio."}
            reject(resp)
        }
    })
}

export function httpPostProxy(route, data, url){
    return new Promise((resolve, reject) => {
        try {
            let headers = {
                "content-type": "application/json",
            }
            if(url){
                headers["url"] = url;
            }

            fetch(route, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data),
            
                }).then(response => response.json())
                .then(response => {
                        resolve(response);
                }).catch(err => {
                    let resp = {cod:1, mensaje:"Error en consulta post Proxy."};
                    reject(resp);
                });
        } catch (error) {
            let resp = {cod: 1, mensaje: "Error al llamar al servicio."}
            reject(resp)
        }
    })
}


export function httpGet(route, token){
    return new Promise((resolve, reject) => {
         try {
            let headers ={};
            if(token){
                headers["Authorization"] = token;
                headers["content-type"] = "application/json";
                headers["Access-Control-Allow-Origin"] = "*";
                headers["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept, Authorization"
            }else{
                headers = {
                    "cookie": "__cfduid=d617af3774d73625e18493a9d342720fc1598981533",
                    "content-type": "application/json",
                    "Access-Control-Allow-Origin": "",
                    "Access-Control-Allow-Credentials": "true",
                    "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
                    "Access-Control-Max-Age": "3600",
                    "Access-Control-Expose-Headers":"Authorization"
                }
            }
            fetch(route, {
                "method": "GET",
                "headers": headers,
                //"mode":"no-cors"
                }).then(response => response.json())
                .then(response => {
                        let resp = {cod:0, data:response};
                        resolve(resp);
                }).catch(err => {
                    console.error("HttpGet Err:",err.toString());
                    let resp = {cod:1, mensaje:"Error en consulta."};
                    reject(resp);
                });
        } catch (error) {
            let resp = {cod: 1, mensaje: "Error al llamar al servicio."}
            reject(resp)
        }
    })
}
