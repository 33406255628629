import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import React, { useEffect, useState } from "react";
import { useStore } from "../../../context";
import { makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FormLabel from '@material-ui/core/FormLabel';
import Button from "@material-ui/core/Button";

import StudentLevel from "../../../components/StudentLevel";
import "./index.css";
import {urlMatRub} from "../../../utils"
import { httpGet } from "../../../servicios";

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
const useStyles1 = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

function ccyFormat(num) {
    return `${num.toFixed(2)}`;
}

export default function Matricula(props) {
  const { state, dispatch } = useStore();
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [error, setError] = useState(false);
  const [nivel, setNivel] = useState("01");
  const [course, setCourse] = useState("");
  const [estud, setEstud] = useState("");
  const [disableMatBi, setDisableMatBi] = useState(false);
  const [disableSegBi, setDisableSegBi] = useState(true);

  const classes = useStyles();
  const classes1 = useStyles1();

  //let disableMatBi = false;
  //let disableSegBi = true;

  const niveles = {
    "01":"Primaria Matutina",
    "17":"Secundaria Basico Matutina",
    "14":"Secundaria Bachillerato Matutina",
    "03":"Primaria Vespertina",
    "18":"Secundaria Basico Vespertina",
    "15":"Secundaria Bachillerato Vespertina",
    "05":"Preescolar",
    "06":"Primaria Femenino",
    "19":"Secundaria Basico Femenino",
    "16":"Secundaria Bachillerato Femenino",
    "13":"Bachillerato Internacional",
    "12":"Educacion Abierta",
  }

  const fillTable = (level,course) => {
    setNivel(level);
    setCourse(course);
    const urlTemp = urlMatRub+state.opciones.periodoinscripcion+"/"+level;
    httpGet(urlTemp, state.token)
    .then((res) => {
      if(res.cod === 0){
        if(res.data.error){
          setError(true);
          return;
        }
          const filaTemp = res.data.map((fila)=>{
            fila.codEst = props.location.state.cod;
            fila.est = estud;
            fila.idInscr = props.location.state.idIns;
            fila.course = course;
            return fila;
          })
          if(res.data.length ===0){
            alert("Sin matriculas que pagar")
          }
          setRows(filaTemp);
      }else{
        setError(true)
        setRows([])
      }
    }).catch((err)=>{
      console.log(err);
      setError(true);
      })
    .finally(() => setLoading(false));
  };

  const addCart = (itemCart) => {
    if(nivel=="13"){

      //Validacion de existencia de filas en carrito
      const existePrevio = state.cartMat.find((item)=>
        item.periodo + item.cod_niv + item.cen_cos === itemCart.periodo + itemCart.cod_niv + itemCart.cen_cos
      );

      if(itemCart.des_rub.trim() === "SEGURO ESTUDIANTIL"){
        const itemActual = state.cartMat.find(
          (item) =>
            item.periodo + item.cod_niv + item.des_rub  +item.cen_cos ===
            itemCart.periodo + itemCart.cod_niv + itemCart.des_rub + itemCart.cen_cos
        );
        if(itemActual){
          alert("Ya existe seguro en carrito");
          return;
        }else if(existePrevio){
          dispatch({ type: "ADD_CART_MAT", payload: itemCart });
          alert("Agregado a carrito");
          return;
        }else{
          alert("Seleccione primero una matricula");
          return;
        }
      }

      if(existePrevio){
        alert("Solo puede seleccionar una matricula");
        return;
      }

      //Validar si ya existe fila en carrito
      const itemActual = state.cartMat.find(
        (item) =>
          item.periodo + item.cod_niv + item.des_rub  +item.cen_cos ===
          itemCart.periodo + itemCart.cod_niv + itemCart.des_rub + itemCart.cen_cos
      );
      if(itemActual){
        alert("Ya existe rubro en carrito")
      }else{
        dispatch({ type: "ADD_CART_MAT", payload: itemCart });
        alert("Agregado a carrito");
      }
      return;
    }
    dispatch({ type: "ADD_CART_MAT", payload: itemCart });
  };

  let itemSelect = 0;
  
  const isEnabled = (row) => {
    const itemActual = state.cartMat.find(
      (item) =>
        item.periodo + item.cod_niv + item.des_rub  +item.cen_cos ===
        row.periodo + row.cod_niv + row.des_rub + row.cen_cos
    );
    let indice =
      itemActual &&
      rows.findIndex(
        (item) =>
          item.periodo + item.cod_niv + item.des_rub  +item.cen_cos ===
          itemActual.periodo + itemActual.cod_niv + itemActual.des_rub + itemActual.cen_cos
      ) + 1;
      
    if(indice){
      if(nivel==="13"){
        if(indice===1){
          indice++;
        }
      }
      itemSelect = indice;
    }
  };

    useEffect(() => {
        setEstud(props.location.state.nom)
        setLoading(false);
    }, [state]);

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        Hubo un error al cargar los datos —{" "}
        <strong>
          <a href="/matricula/list">Intentar de nuevo.</a>
        </strong>
      </Alert>
    );
  }
  return (
    <div>
      <FormLabel>{estud}</FormLabel>
      <br></br>

      <StudentLevel filters={fillTable}/>

      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
          <TableRow>
            <TableCell>Periodo</TableCell>
            <TableCell align="right">Cod. Est</TableCell> 
            <TableCell align="right">Nombres</TableCell>
            <TableCell align="right">Nivel</TableCell>
            <TableCell align="right">Curso</TableCell>
            <TableCell align="right">Rubro</TableCell>
            <TableCell align="right">Valor</TableCell>
            <TableCell />
          </TableRow>
          </TableHead>
          <TableBody>
            {
              nivel==="13" ?
                rows.map((row, index) => {
                  //existInCart(row)
                  return (
                  <TableRow
                      key={index}
                  >
                    <TableCell>{row.periodo}</TableCell>
                    <TableCell align="right">{row.codEst}</TableCell>
                    <TableCell align="right">{row.est}</TableCell>
                    <TableCell align="right">{niveles[nivel]}</TableCell>
                    <TableCell align="right">{row.course}</TableCell>
                    <TableCell align="right">{row.des_rub}</TableCell>
                    <TableCell align="right">$ {ccyFormat(row.val_rub)}</TableCell>
                    <TableCell>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes1.button}
                        size="small"
                        disabled={false}
                        onClick={() => addCart(row)}
                    >
                      Agregar
                    </Button>
                    </TableCell>
                  </TableRow>
                  );
                })
              :
                rows.map((row, index) => {
                  isEnabled(row);
                  return (
                  <TableRow
                      key={index}
                  >
                    <TableCell>{row.periodo}</TableCell>
                    <TableCell align="right">{row.codEst}</TableCell>
                    <TableCell align="right">{row.est}</TableCell>
                    <TableCell align="right">{niveles[nivel]}</TableCell>
                    <TableCell align="right">{row.course}</TableCell>
                    <TableCell align="right">{row.des_rub}</TableCell>
                    <TableCell align="right">$ {ccyFormat(row.val_rub)}</TableCell>
                    <TableCell>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes1.button}
                        size="small"
                        disabled={itemSelect !== index }
                        onClick={() => addCart(row)}
                    >
                      Agregar
                    </Button>
                    </TableCell>
                  </TableRow>
                  );
                })
            }
              
          </TableBody>
        </Table>
    </TableContainer>
    </div>
  );
}