import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Button, FormControl, Input } from "@material-ui/core";

export default function StudentLevel(props) {

  const [nivel, setNivel] = useState("01");
  const [curso, setCurso] = useState("");

  //-------------------------------------------------------------------------
  // Estilos de tarjeta para Matricula/Search/######
  //-------------------------------------------------------------------------
  const useStyles = makeStyles((theme)=>({
    formControl: {
      margin: theme.spacing(2),
      minWidth: 130,
    },
    niveles: {
      marginBottom: 10,
    },
    button: {
      margin: theme.spacing(1),
    },
  }));

  const classes = useStyles();

  const handleChange = (event) => {
    setNivel(event.target.value);
  };

  //-------------------------------------------------------------------------
  // JSX de tarjeta para Matricula/Search/######
  //-------------------------------------------------------------------------
  return (
    <div>
      <FormControl className={classes.formControl}>
        <FormLabel>Nivel</FormLabel>
        <Select
            value={nivel}
            onChange={handleChange}
            className = {classes.niveles}
          >
          <MenuItem value={"01"}>Primaria Matutina</MenuItem>
          <MenuItem value={"17"}>Secundaria Basico Matutina</MenuItem>
          <MenuItem value={"14"}>Secundaria Bachillerato Matutina</MenuItem>
          <MenuItem value={"03"}>Primaria Vespertina</MenuItem>
          <MenuItem value={"18"}>Secundaria Basico Vespertina</MenuItem>
          <MenuItem value={"15"}>Secundaria Bachillerato Vespertina</MenuItem>
          <MenuItem value={"05"}>Preescolar</MenuItem>
          <MenuItem value={"06"}>Primaria femenino</MenuItem>
          <MenuItem value={"19"}>Secundaria Basico Femenino</MenuItem>
          <MenuItem value={"16"}>Secundaria Bachillerato Femenino</MenuItem>
          <MenuItem value={"13"}>Bachillerato Internacional</MenuItem>
          <MenuItem value={"12"}>Educacion Abierta</MenuItem>
        </Select>
      </FormControl>
      <br></br>
      <FormControl className={classes.formControl}>
        <FormLabel>Curso</FormLabel>
        <Input value={curso} onInput={e => setCurso(e.target.value)}/>
        <Button 
          className={classes.button}
          variant="contained"
          color="primary" 
          onClick={() => {curso.length>3 ? props.filters(nivel,curso) : alert("Llene el campo de curso")}}
        >Buscar</Button>
      </FormControl>
    </div>
  );
}
