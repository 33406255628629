import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { CopyRight } from "../../../components/Copyright";
import "./index.css";
import { navigate } from "@reach/router";

export default () => {
  /*React.useEffect(() => {
    if (!state.token) {
      navigate("/home");
    }
  }, [state]);*/
  return (
    <div className="flex">
      <main className="content">
        <Container component="main" maxWidth="xs">
        <div className="content-login">
          <Typography component="h1" variant="h5">
            Matriculas
          </Typography>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="button-login"
            onClick={()=>{navigate("authNew")}}
          >
            NUEVO ESTUDIANTE
          </Button>
          <Button 
            fullWidth
            variant="contained"
            color="secondary"
            className="button-login"
            onClick={()=>{navigate("authAnai")}}
            //href={`${urlAuth}oauth/authorize?response_type=code&client_id=oauth2-jwt-client&redirect_uri=${url}authOld`}
            //onClick={()=>{navigate("inscripciones")}}
            >
            ESTUDIANTE ANAI
          </Button>
        </div>
        <CopyRight />
      </Container>
      </main>
    </div>

  );
};
