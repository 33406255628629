import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { navigate } from "@reach/router";

//-------------------------------------------------------------------------
// Estilos de la tarjeta de la pagina de matricula/list
//-------------------------------------------------------------------------
const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

//-------------------------------------------------------------------------
// JSX de la tarjeta usada en Matricula/list
//-------------------------------------------------------------------------
export default function MatStudentCard(props) {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardActionArea
        onClick={() => {
          navigate("search/"+props.student.cod_est,{ state: { nom: props.student.nom_est, cod: props.student.cod_est, idIns: props.student.id_inscripcion} });
        }}
      >
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Codigo: {props.student.cod_est}
          </Typography>
          <Typography variant="h5" component="h2">
            Nombres: {props.student.nom_est}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
