export default class PagoWebCab{
    constructor(
        idSobre,
        moneda,
        valorTotal,
        formaPago,
        codBco,
        tipoCta,
        ctaCte,
        tipIden,
        numCed,
        nomCli,
        fechaProceso,
        horaProceso,
        condProceso,
        mensajeProceso,
        numdoc,
        canal,
        numeroSri,
        staCob,
        dscObs,
        emailPayer,
        docPayer,
        referencia,
        urlProcess,
        tipotran
    ){
        this.idSobre = idSobre;
        this.moneda = moneda;
        this.valorTotal = valorTotal;
        this.formaPago = formaPago;
        this.codBco = codBco;
        this.tipoCta = tipoCta;
        this.ctaCte = ctaCte;
        this.tipIden = tipIden;
        this.numCed = numCed;
        this.nomCli = nomCli;
        this.fechaProceso = fechaProceso;
        this.horaProceso = horaProceso;
        this.condProceso = condProceso;
        this.mensajeProceso = mensajeProceso;
        this.numdoc =  numdoc;
        this.canal = canal;
        this.numeroSri = numeroSri;
        this.staCob = staCob;
        this.dscObs = dscObs;
        this.emailPayer = emailPayer;
        this.docPayer = docPayer;
        this.referencia = referencia;
        this.urlProcess = urlProcess;
        this.tipotran = tipotran;
    }
}