export default class Pagador{
    constructor(
        tipDoc,
        cedula,
        nombres,
        apellidos,
        correo,
        celular,
        direccion
    ){
        this.tipDoc = tipDoc;
        this.cedula = cedula;
        this.nombres = nombres;
        this.apellidos = apellidos;
        this.correo = correo;
        this.celular = celular;
        this.direccion = direccion;
    }}