import React, { forwardRef }from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

//-------------------------------------------------------------------------
// Componente Reutilizable que sirve como alert usando componentes de 
// Material UI
//-------------------------------------------------------------------------

function handleClose(props){
    props.cerrar(false);
}

const Info = forwardRef((props, refe) => {
    return(
        <Dialog
            open={props.visible}
            ref={refe}
            onClose={()=>handleClose(props)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" ref={refe}>{props.titulo}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" ref={refe}>
                    {props.mensaje}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button ref={refe} onClick={()=>handleClose(props)} color="primary">
                Aceptar
                </Button>
            </DialogActions>
        </Dialog>)
});

export default Info;