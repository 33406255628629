import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React from "react";
import { CopyRight } from "../../components/Copyright";
import "./index.css";
import { navigate } from "@reach/router";
import Link from '@material-ui/core/Link';
import { useStore } from "../../context";
import TagManager from 'react-gtm-module'



export default () => { 
  TagManager.dataLayer({
    dataLayer: {
      event: "pageview",
      path: "/menuMain"
    }
  });
  
  const { dispatch } = useStore();

  

  const handleClickOpenPension = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        path: "/pension"
      }
    });
    // console.log("PRUEBA")
    navigate("/pension/list");
  };

  const handleClickOpenInscripcion = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        path: "/inscripcion"
      }
    });
    // console.log("PRUEBA")
    navigate("/inscripcion/list");
  };
  
  return (
    <div className="main-app">
      <Container component="main" maxWidth="xs">
        <div className="content-login">
          <Avatar className="avatar-login">
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Portal de Inscripciones , matrícula y cobros
          </Typography>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="button-login"
            onClick={()=>{handleClickOpenPension()}}
            // onClick={handleClickOpenPension} 
            // onClick={()=>{navigate("/pension/list")}}
            //href={`${urlAuth}oauth/authorize?response_type=code&client_id=oauth2-jwt-client&redirect_uri=${url}auth`}
          >
            PAGAR PENSIÓN
          </Button>
          <Button 
            fullWidth
            variant="contained"
            className="button-login"
            onClick={()=>{handleClickOpenInscripcion()}}
            // onClick={()=>{navigate("authIns")}}
            //href={`${urlAuthMysql}oauth/authorize?response_type=code&client_id=oauth2-jwt-client&redirect_uri=${url}authIns`}
            //onClick={()=>{navigate("inscripciones")}}
            >
            ADMISIONES DE ALUMNOS NUEVOS 
          </Button>
          <Button 
            fullWidth
            variant="contained"
            color="secondary"
            className="button-login"
            onClick={()=>{navigate("matMenu")}}
            >
            MATRICULACION 
          </Button>
          <Button 
            fullWidth
            variant="contained"
            className="button-login"
            onClick={()=>{
              dispatch({ type: "REMOVE_TOKEN" });
              navigate("/");
            }}
            >
            salir sistema 
          </Button>
        </div>
        <CopyRight />
      </Container>
    </div>
  );
};
