export default class WebLink{
    constructor(
        id,
        idLink,
        item,
        codEst,
        nomEst,
        periodo,
        codNiv,
        rubroCod,
        rubroNom,
        referencia,
        valor,
        estado,
        email,
        fchRegistro,
        username,
        token
    ){
        this.id=id;
        this.idLink=idLink;
        this.item=item;
        this.codEst=codEst;
        this.nomEst=nomEst;
        this.periodo=periodo;
        this.codNiv=codNiv;
        this.rubroCod=rubroCod;
        this.rubroNom=rubroNom;
        this.referencia=referencia;
        this.valor=valor;
        this.estado=estado;
        this.email=email;
        this.fchRegistro=fchRegistro;
        this.username=username;
        this.token=token;
    }
}