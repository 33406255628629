export default class Representante{
    constructor(
        id_inscripcion,
        tipoDoc,
        numDoc,
        nombres,
        apellidos,
        correo,
        numCelular,
        numTelf,
        usuarioCrea,
        fechaCrea,
        usuarioModif,
        fechaModif,
        estado,
        tipo_representante
    ){
        this.id_inscripcion = id_inscripcion;
        this.tipoDoc = tipoDoc;
        this.numDoc = numDoc;
        this.nombres = nombres;
        this.apellidos = apellidos;
        this.correo = correo;
        this.numCelular = numCelular;
        this.numTelf = numTelf;
        this.usuarioCrea = usuarioCrea;
        this.fechaCrea = fechaCrea;
        this.usuarioModif = usuarioModif;
        this.fechaModif = fechaModif;
        this.estado = estado;
        this.tipo_representante = tipo_representante;
    }
}