import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import BackupIcon from '@material-ui/icons/Backup';
import CreateIcon from '@material-ui/icons/Create';
import React from "react";
import { navigate } from "@reach/router";
import Tooltip from '@material-ui/core/Tooltip';

//-------------------------------------------------------------------------
// Menu Especifico para Inscripciones
//-------------------------------------------------------------------------

const menuInsc = (
  <div>
    <Tooltip title="Inscripciones">
      <ListItem button onClick={() => navigate("/inscripcion/list")}>
        <ListItemIcon>
          <CreateIcon />
        </ListItemIcon>
        <ListItemText primary="Inscripciones" />
      </ListItem>
    </Tooltip>
    
    <Tooltip title="Cargar archivos">
      <ListItem button onClick={() => navigate("/inscripcion/fileUpload")}>
        <ListItemIcon>
          <BackupIcon />
        </ListItemIcon>
        <ListItemText primary="Cargar archivos" />
      </ListItem>
    </Tooltip>
  </div>
);

export default menuInsc;
