import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import React from "react";

//-------------------------------------------------------------------------
// El CopyRight © 2021 Academia Naval Almirante Illingworth.
//-------------------------------------------------------------------------

export const CopyRight = () => {
  return (
    <Box pt={4}>
      <Typography variant="body2" color="textSecondary" align="center">
        {"© " + new Date().getFullYear()}
        <Link color="inherit" href="https://anai.edu.ec/" target="_blank">
          {" Academia Naval Almirante Illingworth."}
        </Link>
      </Typography>
    </Box>
  );
};
