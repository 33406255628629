import { MuiThemeProvider } from "@material-ui/core/styles";

import React from "react";
import ReactDOM from "react-dom";
import theme from "./assets/theme/";
import "./index.css";
import { StoreProvider } from "./context";
import Ruteador from "./Ruteador";
import TagManager from 'react-gtm-module'



//google tag jf
//  const tagManagerArgs = {
//   gtmId: 'GTM-TZSNK5R'
// } 


//goggle tag marketing
const tagManagerArgs = {
  gtmId: 'GTM-K5C85JH'
}

TagManager.initialize(tagManagerArgs)


// TagManager.dataLayer({
//   dataLayer: {
//     event: "pageview",
//     path: "/"
//   }
// })

//-------------------------------------------------------------------------
//Enrutador de la aplicacion de React
//-------------------------------------------------------------------------
 
ReactDOM.render(
  <React.StrictMode>
    <StoreProvider>
      <MuiThemeProvider theme={theme}>
        <Ruteador/>
      </MuiThemeProvider>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
