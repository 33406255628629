import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import React, { useEffect, useState } from "react";

import { useStore } from "../../context";
import "./index.css";
import TableContainer from "@material-ui/core/TableContainer";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import ArchiveIcon from '@material-ui/icons/Archive';
import { urlDashBoard } from "../../utils";
import { httpGet } from "../../servicios";

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
const useStyles1 = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}
export default function Rubros(props) {
  const { state, dispatch } = useStore();
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [rowsAll, setRowsAll] = useState([]);

  const [total, setTotal] = useState(0);
  const [error, setError] = useState(false);
  const [disabledAll, setDisabledAll] = useState(false);
  const [disabledTrans, setDisabledTrans] = useState(false);

  const classes = useStyles();
  const classes1 = useStyles1();
  

  const addCart = (itemCart) => {
    dispatch({ type: "ADD_CART", payload: itemCart });
  };

  const filtroDocumentos = (tipo) => {
    let rowsTemp = [];
    console.log('Transportes Rows.SIZE...>>> ',rows.length+' Tipo='+tipo);  
    if ( tipo === 'T' ){
      for (let index = 0; index < rows.length; index++) {
        console.log('Transportes...>>> ',rows[index]);  
        if (rows[index].abrRub.includes('TRANSPORTE')){
          rowsTemp.push(rows[index])
        }
      }
      if (rowsTemp.length >0) {
         setRows(rowsTemp);
         totales(rowsTemp);
         setDisabledTrans(true);
      }
    } else {
      setRows(rowsAll);
      totales(rowsAll);
      setDisabledTrans(true);
    }

  };

  useEffect(() => {
    // console.log(props);
    setLoading(true);
    httpGet(urlDashBoard+props.codEst, state.token)
    .then((res) => {
      if(res.cod === 0){
          //console.log(res)
          if(res.data.error){
            setError(true);
            return;
          }
          setRows(res.data);
          setRowsAll(res.data);
          
          totales(res.data);
      }else{
        setRows([])
      }
      
    })
    .catch(()=>{setRows([])})
    .finally(() => setLoading(false));
  }, 
  [state.token]);
 
  const totales = (data) => {
    let sumatoria = 0;
    data.map((item) => {
      sumatoria = sumatoria + parseFloat(item.valor);
    });
    setTotal(sumatoria.toFixed(2));
  }

  let itemSelect = 0;
  //console.log("sadas" + itemSelect);
  const isEnabled = (row) => {
    const itemActual = state.cart.find( (item) =>
        item.periodo + item.codNiv + item.codEst  +item.codRub ===
        row.periodo + row.codNiv + row.codEst + row.codRub
    );
    const indice =  itemActual &&  rows.findIndex(
        (item) =>
          item.periodo + item.codNiv + item.codEst  +item.codRub ===
          itemActual.periodo + itemActual.codNiv + itemActual.codEst + itemActual.codRub ) + 1;
    if (indice) itemSelect = indice;
  };

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        Hubo un error al traer los datos —{" "}
        <strong>
          <a href="/pensiones/rubros">reintentar.</a>
        </strong>
      </Alert>
    );
  }
  return (
    <TableContainer component={Paper}>
     
        <Button
          variant="contained"
          color="primary"
          className={classes1.button}
          endIcon={<DirectionsBusIcon />}
          size="small"
          disabled={disabledTrans}
          onClick={() => filtroDocumentos('T')}
        >
          Solo deseo pagar Transporte
        </Button>
       

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Periodo</TableCell>
             <TableCell align="right">Cod. Est</TableCell> 
            <TableCell align="right">Nombres</TableCell>
            <TableCell align="right">Cod. Rubro</TableCell>
            <TableCell align="right">Nivel</TableCell>
            {/* <TableCell align="right">Cod. Cur</TableCell> */}
            <TableCell align="right">Curso</TableCell>
            <TableCell align="right">Rubro</TableCell>
            <TableCell align="right">Valor</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            isEnabled(row);

            return (
              <TableRow key={row.codRub} >
                <TableCell>{row.periodo}</TableCell>
                <TableCell align="right">{row.codEst}</TableCell>
                <TableCell align="right">{row.nomEst}</TableCell>
                <TableCell align="right">{row.codRub}</TableCell>
                <TableCell align="right">{row.desCos}</TableCell>
                {/* <TableCell align="right">{row.codCur}</TableCell> */}
                <TableCell align="right">{row.abrCur}</TableCell>
                <TableCell align="right">{row.abrRub}</TableCell>
                <TableCell align="right">$ {ccyFormat(row.valor)}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes1.button}
                    endIcon={<AddShoppingCartIcon />}
                    size="small"
                    disabled={itemSelect !== index}
                    onClick={() => addCart(row)}
                  >
                    Agregar
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell colSpan={5}></TableCell>
            <TableCell colSpan={2} style={{fontWeight:"bold"}}>Total</TableCell>
            <TableCell align="right" style={{fontWeight:"bold"}}>$ {total}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
