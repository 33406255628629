import { useStore } from "../../../context";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { CopyRight } from "../../../components/Copyright";
import "./index.css";
import { navigate } from "@reach/router";
import { httpGet } from "../../../servicios";
import { urlOpciones } from "../../../utils";
import TagManager from "react-gtm-module";

export default () => {
  const { state, dispatch } = useStore();


  const handleClickOpenEstAnai = () => {

    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        path: "/matricula/anai"
      }
    });
    console.log("ESTUDIANTE ANAI");
    // let _opciones = httpGet(urlOpciones,state.token);
    // console.log("opciones"+_opciones.data);
    dispatch({ type:"SET_LOGIN", payload:"A"});
    navigate("/matricula/list");
    // navigate("/pension/list");
  };

  const handleClickOpenEstNuevo = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        path: "/matricula/nuevo"
      }
    });
    console.log("ESTUDIANTE NUEVO");
    // let _opciones = httpGet(urlOpciones,state.token);
    // console.log("opciones"+_opciones.data);
    dispatch({ type:"SET_LOGIN", payload:"N"});
    navigate("/matricula/list"); 
    // navigate("/pension/list");
  };


  
  /*React.useEffect(() => {
    if (!state.token) {
      navigate("/home");
    }
  }, [state]);*/
  return (
    <div className="flex">
      <main className="content">
        <Container component="main" maxWidth="xs">
        <div className="content-login">
          <Typography component="h1" variant="h5">
            Matriculas
          </Typography>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="button-login"
            onClick={()=>{handleClickOpenEstNuevo()}}
            // onClick={()=>{navigate("authNew")}}
          >
            NUEVO ESTUDIANTE
          </Button>
          <Button 
            fullWidth
            variant="contained"
            color="secondary"
            className="button-login"
            onClick={()=>{handleClickOpenEstAnai()}}
            // onClick={()=>{navigate("/pension/list")}}
            // onClick={handleClickOpenEstAnai}
            // onClick={()=>{navigate("/matricula/list")}}
            //href={`${urlAuth}oauth/authorize?response_type=code&client_id=oauth2-jwt-client&redirect_uri=${url}authOld`}
            //onClick={()=>{navigate("inscripciones")}}
            >
            ESTUDIANTE ANAI
          </Button>
        </div>
        <CopyRight />
      </Container>
      </main>
    </div>

  );
};
