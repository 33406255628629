export default class LogPago{
    constructor(
    secuencia,  
    idItem, 
    moneda,
    valor,
    codEstado,
    mensaje,
    fechaProceso,
    horaProceso,
    numCed,
    nomCliente,
    codRub,
    codEst,
    codNiv,
    periodo,
    usuario,
    referencia,
    ){
    this.secuencia = secuencia;
    this.idItem = idItem;
    this.moneda = moneda;
    this.valor = valor;
    this.codEstado = codEstado;
    this.mensaje = mensaje;
    this.fechaProceso = fechaProceso;
    this.horaProceso = horaProceso;
    this.numCed = numCed;
    this.nomCliente = nomCliente;
    this.codRub = codRub;
    this.codEst = codEst;
    this.codNiv = codNiv;
    this.periodo = periodo;
    this.usuario = usuario;
    this.referencia = referencia;
    }
}